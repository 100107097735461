import React, { useContext } from "react";
import MyContext from "../../context/data/myContext";
import { Link } from "react-router-dom";

function UpdateFact2() {
  const context = useContext(MyContext);
  const { fac2, setFact2, updateFactura2 } = context;

  const handleUpdateFactura = async () => {
    await setFact2((prevFac) => ({ ...prevFac, process: "FACTURADO" }));
    if (fac2.process === "FACTURADO") {
      // Only allow the update if the process is "FACTURADO"
      if (updateFactura2 && typeof updateFactura2 === "function") {
        try {
          await updateFactura2();
          console.log("Factura updated successfully.");
        } catch (error) {
          console.error("Error updating factura:", error);
        }
      }
    }
  };

  return (
    <div className="p-0 sm:p-6">
      <div className=" mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg">
        <h1 className="text-center text-xl mb-4">POST FILE</h1>
        <h1 className="text-center  text-xs mb-4">Nota: Doble Click</h1>
        <div className="flex flex-col mb-3">
          <input
            type="text"
            value={fac2.factura}
            onChange={(e) => setFact2({ ...fac2, factura: e.target.value })}
            name="fac"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Factura"
            style={{ width: "90%" }}
          />
        </div>

        <div className=" flex justify-center mb-3">
          <Link
            to="../asana"
            className="bg-clgris  hover:bg-clgris text-white font-bold py-2.5 px-4  rounded m-2"
          >
            Regresar
          </Link>
          <button
            onClick={handleUpdateFactura}
            className="bg-clgris  hover:bg-clgris text-white font-bold py-2 px-4 rounded m-2"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateFact2;
