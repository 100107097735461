import React, { useContext } from "react";
import MyContext from "../../context/data/myContext";
import { Link } from "react-router-dom";

function AddCategory() {
  const context = useContext(MyContext);
  const { cats, setCats, addCat } = context;
  return (
    <div className="p-0 sm:p-6">
      <div className="mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg">
        <h1 className="text-center text-xl mb-4">
          Agregar Categoría
        </h1>
        <div className="flex flex-col mb-3">
          <input
            type="text"
            onChange={(e) => setCats({ ...cats, paquete: e.target.value })}
            value={cats.paquete}
            name="paquete"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Paquete"
            style={{ width: "90%" }}
          />
        </div>
        <div className="flex flex-col mb-3">
          <input
            type="text"
            onChange={(e) => setCats({ ...cats, subpaquete: e.target.value })}
            value={cats.subpaquete}
            name="subpaquete"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Sub Paquete"
            style={{ width: "90%" }}
          />
        </div>
        <div className="flex flex-col mb-3">
          <input
            type="text"
            onChange={(e) => setCats({ ...cats, categoria: e.target.value })}
            value={cats.categoria}
            name="categoria"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Categoría"
            style={{ width: "90%" }}
          />
        </div>
        <div className="flex flex-col mb-3">
          <label>Seleccione color de Fondo</label>
          <div className="flex items-center">
          <input
            type="color"
            id="bcolor"
            name="bcolor"
            value={cats.bcolor}
            onChange={(e) => setCats({ ...cats, bcolor: e.target.value })}
            className="mr-2"
          />
          <input
            type="text"
            value={cats.bcolor}
            onChange={(e) => setCats({ ...cats, bcolor: e.target.value })}
            placeholder={cats.bcolor}
            className="p-2 border border-gray-300 rounded-md"
          />
          </div>
        </div>
        <div className="flex flex-col mb-3">
          <label>Seleccione color de Texto</label>
          <div className="flex items-center">
            <input
              type="color"
              id="tcolor"
              name="tcolor"
              value={cats.tcolor}
              onChange={(e) => setCats({ ...cats, tcolor: e.target.value })}
              className="mr-2"
            />
            <input
              type="text"
              value={cats.tcolor}
              onChange={(e) => setCats({ ...cats, tcolor: e.target.value })}
              placeholder={cats.tcolor}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div style={{ marginTop: '20px', }}>
          <div
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: cats.bcolor,
              color: cats.tcolor,
              border: '1px solid #ccc',
              borderRadius: '4px',
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {cats.categoria}
          </div>
        </div>


        <div className=" flex justify-center mb-3">
          <Link
            to="../"
            className="bg-clgris  hover:bg-clgris text-white font-bold py-2.5 px-4  rounded m-2"
          >
            Regresar
          </Link>
          <button
            onClick={addCat}
            className="bg-clgris  hover:bg-clgris text-white font-bold py-2 px-4 rounded m-2"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
