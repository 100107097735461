import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import MyContext from "../../context/data/myContext";
import Select from "react-select";
import { toast } from "react-toastify";

const UpdateBudPen = () => {
  const context = useContext(MyContext);
  const { getPenData, book, getBookData } = context;

  useEffect(() => {
    getBookData();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const [bud, setBud] = useState(null);
  const [newname, setNewname] = useState("");
  const [newtel, setTel] = useState("");
  const [newmedia, setMedia] = useState("");
  const [newshipping, setNewshipping] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [editedBudget, setEditedBudget] = useState([
    {
      quantity: Number(""),
      alias: "",
      category: "",
      date: "",
      id: "",
      law: "",
      price: "",
      ref: "",
      time: "",
      title: "",
      lawcolor: "",
      origen: "",
    },
  ]);

  const [totalAmount, setTotalAmount] = useState(0);
  const grandTotal2 = totalAmount + Number(newshipping) - discountPercentage;
  const grandTotal = parseFloat(grandTotal2.toFixed(2));
  

  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await getDoc(doc(fireDB, "budPendiente", id));
      if (userDoc.exists()) {
        setBud(userDoc.data());
        setNewname(userDoc.data().name);
        setTel(userDoc.data().phoneNumber);
        setMedia(userDoc.data().media);
        setNewshipping(userDoc.data().newshipping);
        setDiscountPercentage(userDoc.data().discountPercentage);
        setTotalAmount(userDoc.data().grandTotal);
        setEditedBudget(
          userDoc.data().budgetItems.map((item) => ({
            quantity: Number(item.quantity),
            alias: item.alias,
            category: item.category,
            date: item.date,
            id: item.id,
            law: item.law,
            price: item.price,
            ref: item.ref,
            time: item.time,
            title: item.title,
            lawcolor: item.lawcolor || "",
            origen: item.origen || "",
          }))
        );
      }
    };

    fetchUser();
  }, [id]);

  const handleUpdate = async () => {
    const updatedBudget = {
      ...bud,
      name: newname,
      phoneNumber: newtel,
      newshipping: newshipping,
      media: newmedia,
      discountPercentage: discountPercentage,
      budgetItems: editedBudget.map((service) => ({
        quantity: Number(service.quantity),
        alias: service.alias,
        category: service.category,
        date: service.date,
        id: service.id,
        law: service.law,
        price: service.price,
        ref: service.ref,
        time: service.time,
        title: service.title,
        lawcolor: service.lawcolor || "",
        origen: service.origen || "",
      })),
    };

    
    

    try {
      await updateDoc(doc(fireDB, "budPendiente", id), updatedBudget);
      console.log("Actualizado");
      toast.success("Actualizacion realizada", {
        position: toast.POSITION.TOP_LEFT,
      });
  
      // Check for changes in quantity, shipping, discount, or item count
      const quantityChanged = editedBudget.some(
        (service, index) => {
          // Manejar el nuevo elemento
          if (index >= bud.budgetItems.length) {
            return true;
          }
          return service.quantity !== bud.budgetItems[index].quantity;
        }
      );
    const shippingChanged = newshipping !== bud.newshipping;
    const discountChanged = discountPercentage !== bud.discountPercentage;
    const itemCountChanged = editedBudget.length !== bud.budgetItems.length;

    // If there are changes, execute handleTotal
    if (quantityChanged || shippingChanged || discountChanged || itemCountChanged) {
      await handleTotal();
    }
  
      await getPenData();
  
      navigate("../budPendiente");
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };

  const handleServiceChange = (index, value) => {
    const updatedServices = [...editedBudget];
    updatedServices[index].quantity = value;
    setEditedBudget(updatedServices);
  };

  const handleTotal = async () => {
    const updatedBudget = {
      ...bud,
      grandTotal: grandTotal,
      name: newname,
      phoneNumber: newtel,
      media: newmedia,
      newshipping: newshipping,
      discountPercentage: discountPercentage,
      budgetItems: editedBudget.map((service) => ({
        quantity: Number(service.quantity),
        alias: service.alias,
        category: service.category,
        date: service.date,
        id: service.id,
        law: service.law,
        price: service.price,
        ref: service.ref,
        time: service.time,
        title: service.title,
        lawcolor: service.lawcolor || "",
        origen: service.origen || "",
      })),
    };

    try {
      await updateDoc(doc(fireDB, "budPendiente", id), updatedBudget);
      console.log("Total Actualizado");
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };

  

  const newTotal = async () => {
    let total = 0;

    editedBudget.forEach((item) => {
      const price = parseFloat(item.price);
      const quantity = parseFloat(item.quantity, 10);

      if (!isNaN(price) && !isNaN(quantity)) {
        total += price * quantity;
      }
    });
    setTotalAmount(total);
    toast.success("Calculo realizado", {
      position: toast.POSITION.TOP_LEFT,
    });
    return total;
  };

  /*
  const calculateDiscount = (percentage, totalAmount) => {
    return (percentage / 100) * totalAmount;
  };
  */

  

  const handleDeleteService = (index) => {
    const updatedServices = editedBudget.filter((_, i) => i !== index);
    setEditedBudget(updatedServices);
  };

  const handleAddService = () => {
    const newService = {
      quantity: 0,
      alias: "",
      category: "",
      date: "",
      id: "",
      law: "",
      price: "",
      ref: "",
      time: "",
      title: "",
      lawcolor: "",
      origen: "",
    };

    setEditedBudget((prevBudget) => [...prevBudget, newService]);
  };

  const handleServicePropertyChange = (index, property, value) => {
    const updatedServices = [...editedBudget];
    updatedServices[index][property] = value;

    // Buscar el libro correspondiente al título seleccionado
    const selectedBook = book.find((bookItem) => bookItem.ref === value);

    // Llenar los demás campos con la información del libro seleccionado
    if (selectedBook) {
      updatedServices[index].category = selectedBook.category || "";
      updatedServices[index].price = selectedBook.price || "";
      updatedServices[index].alias = selectedBook.alias || "";
      updatedServices[index].date = selectedBook.date || "";
      updatedServices[index].id = selectedBook.id || "";
      updatedServices[index].law = selectedBook.law || "";
      updatedServices[index].ref = selectedBook.ref || "";
      updatedServices[index].time = selectedBook.time || "";
      updatedServices[index].lawcolor = selectedBook.lawcolor || "";
      updatedServices[index].origen = selectedBook.origen || "";
      updatedServices[index].title = selectedBook.title || "";
      // Agrega más líneas según sea necesario para otros campos
    }

    setEditedBudget(updatedServices);
  };

  const handleInputChange = (value, setter) => {
    // Validar si el valor es un número decimal con hasta dos decimales
    if (/^\d+(\.\d{0,2})?$/.test(value)) {
      setter(value);
    }
  };

  return (
    <div>
      <div className=" p-0 sm:p-6">
        <div className=" mx-auto max-w-6xl px-6 py-6 bg-gray-50 border-2 shadow-lg ">
          <div>
            <h1 className="text-center text-xl mb-4">Actualizar Cotizacion</h1>
          </div>

          <div>
            <label
              htmlFor="newname"
              className="block mb-2 text-sm font-medium text-black"
            >
              Nombre: {newname}
            </label>
            <input
              type="name"
              value={newname}
              onChange={(e) => setNewname(e.target.value)}
              name="newname"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div>
            <label
              htmlFor="newtel"
              className="block mb-2 text-sm font-medium text-black"
            >
              Telefono: {newtel}
            </label>
            <input
              type="text"
              value={newtel}
              onChange={(e) => setTel(e.target.value)}
              name="newtel"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div>
            <label
              htmlFor="newmedia"
              className="block mb-2 text-sm font-medium text-black"
            >
              Medio: {newmedia}
            </label>
            <input
              type="text"
              value={newmedia}
              onChange={(e) => setMedia(e.target.value)}
              name="newmedia"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div className="mb-4">
            {editedBudget.map((service, index) => (
              <div key={index} className="mb-2">
                <div>
                  <label
                    htmlFor="quantity"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Cantidad de: {service.title}
                  </label>
                  <label
                    htmlFor="quantity"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Ref: {service.ref}
                  </label>
                  <input
                    type="number"
                    value={service.quantity}
                    onChange={(e) => handleServiceChange(index, Math.max(0, e.target.value))}
                    name="quantity"
                    className=" mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  />

                 
                </div>

                <button
                  onClick={() => handleDeleteService(index)}
                  className="mt-2 p-1 bg-red-500 text-white rounded"
                >
                  Eliminar
                </button>

                <div className=" bg-clgris p-1 mt-4"></div>

                {service.title === "" && (
                  <div>
                    <label
                      htmlFor="newServiceAlias"
                      className="block mb-2 text-sm font-medium text-black"
                    >
                      Nuevo Item:
                    </label>
                    <Select
                      id={index}
                      onChange={(selectedOption) =>
                        handleServicePropertyChange(
                          index,
                          "ref",
                          selectedOption.value
                        )
                      }
                      options={book.map((bookItem) => ({
                        value: bookItem.ref,
                        label: `${bookItem.title} - ${bookItem.ref}`,
                      }))}
                      placeholder="Selecciona un título"
                      className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                      styles={{ width: "90%" }}
                    />
                  </div>
                )}
              </div>
            ))}

            <h1>Subtotal: {totalAmount}</h1>
          </div>

          <div>
            <label
              htmlFor="newshipping"
              className="block mb-2 text-sm font-medium text-black"
            >
              Costo de Envío: {newshipping}
            </label>
            <input
              type="number"
              value={newshipping}
              onChange={(e) => handleInputChange(e.target.value, setNewshipping)}
              name="newshipping"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div>
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-black"
            >
              Descuento: Q {discountPercentage}
            </label>
            <input
              type="number"
              value={discountPercentage}
              onChange={(e) => handleInputChange(e.target.value, setDiscountPercentage)}
              name="discount"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <h1>Total: {grandTotal}</h1>

          <div className=" flex justify-center mb-3">

          <Link className="bg-clgris hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2" to="../budPendiente">
            Regresar
          </Link>

            <button
              onClick={handleAddService}
              className="bg-clgris hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2"
            >
              Agregar
            </button>

            <button
              onClick={newTotal}
              className="bg-clgris hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2"
            >
              Calcular
            </button>

            <button
              onClick={handleUpdate}
              className="bg-clgris hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBudPen;
