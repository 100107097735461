import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore} from 'firebase/firestore';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPDWgm5vTePfWiS42IOQbs0Uqw4q0_Bz4",
  authDomain: "auth-7f657.firebaseapp.com",
  databaseURL: "https://auth-7f657-default-rtdb.firebaseio.com",
  projectId: "auth-7f657",
  storageBucket: "auth-7f657.appspot.com",
  messagingSenderId: "88331415259",
  appId: "1:88331415259:web:19783f23cb4799e625e6fe"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB = getFirestore(app);
const storage = getStorage(app);

export default app
export {fireDB, storage};