import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../../context/data/myContext";
import { Link } from "react-router-dom";
import {
  FaRegFile,
  FaTrash,
  FaRegEdit,
  FaRegFolder,
  FaListUl,
} from "react-icons/fa";
import SearchBar from "../../../components/searchbar/SearchBar";

function Inventory() {
  const context = useContext(MyContext);
  const {
    mode,
    book,
    deleteBook,
    filteredData,
    edithandle,
    getBookData,
    getCatData,
  } = context;

  useEffect(() => {
    getBookData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCatData();
    // eslint-disable-next-line
  }, []);

  const [showExtra, setShowExtra] = useState({}); // State to track whether to show extra text

  // Define una función para determinar los colores basados en item.ref
  function getColorStyles(ref) {
    if (ref.includes("WOLFF")) {
      return {
        textColor: "#4EA807",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("PLUS")) {
      return {
        textColor: "#DBB600",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("DLXW")) {
      return {
        textColor: "white",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("DLX")) {
      return {
        textColor: " #DBB600",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("WOLF")) {
      return {
        textColor: "white",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("CIV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFF00",
      };
    } else if (ref.includes("MERC")) {
      return {
        textColor: "black",
        backgroundColor: "#66FF66",
      };
    } else if (ref.includes("NOT")) {
      return {
        textColor: "black",
        backgroundColor: "#FF9900",
      };
    } else if (ref.includes("ADMIN")) {
      return {
        textColor: "black",
        backgroundColor: "#00B0F0",
      };
    } else if (ref.includes("PEN")) {
      return {
        textColor: "white",
        backgroundColor: "#FF0000",
      };
    } else if (ref.includes("LABO")) {
      return {
        textColor: "black",
        backgroundColor: "#DDC88F",
      };
    } else if (ref.includes("CONV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFFC7",
      };
    } else if (ref.includes("LIB")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("PROTOCOLO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFI")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TN")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } 
    // Agrega más condiciones según tus necesidades

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  // Define the custom order
  const customOrder = [
    "WOLF",
    "PLUS",
    "DLXW",
    "DLX",
    "WOLFF",
    "CIV",
    "MERC",
    "NOT",
    "ADMIN",
    "PEN",
    "LABO",
    "CONV",
  ];

  // Sort the book array based on the custom order and the second structure of item.ref
  book.sort((a, b) => {
    // Function to extract the second structure from item.ref
    const extractSecondStructure = (ref) => {
      const parts = ref.split("-");
      if (parts.length >= 2) {
        return parts[1].trim();
      }
      return ref;
    };

    // Define a custom order for the second structure
    const customOrderSecondStructure = [
      "CONST",
      "ORG",
      "CIV",
      "MERC",
      "NOT",
      "ADMIN",
      "PEN",
      "LABO",
    ];

    const aRef = a.ref;
    const bRef = b.ref;
    const aSecondStructure = extractSecondStructure(aRef);
    const bSecondStructure = extractSecondStructure(bRef);

    // Compare items based on the custom order of the first structure
    const aCustomOrderIndex = customOrder.indexOf(aRef.split("-")[0]);
    const bCustomOrderIndex = customOrder.indexOf(bRef.split("-")[0]);

    // Get the index in the custom order for the second structure
    const aSecondStructureIndex =
      customOrderSecondStructure.indexOf(aSecondStructure);
    const bSecondStructureIndex =
      customOrderSecondStructure.indexOf(bSecondStructure);

    // Compare items based on their custom order index and then by the second structure
    if (aCustomOrderIndex !== -1 && bCustomOrderIndex !== -1) {
      if (aCustomOrderIndex !== bCustomOrderIndex) {
        return aCustomOrderIndex - bCustomOrderIndex;
      }
    } else {
      // If one or both items don't have a custom order index, move them to the end
      if (aCustomOrderIndex === -1 && bCustomOrderIndex === -1) {
        // If both don't have custom order indices, compare by second structure
        if (aSecondStructureIndex !== bSecondStructureIndex) {
          return aSecondStructureIndex - bSecondStructureIndex;
        }
        // If both don't have custom order indices and second structure indices are equal, compare by original order
        return aRef.localeCompare(bRef);
      } else if (aCustomOrderIndex === -1) {
        return 1; // Move item without custom order to the end
      } else {
        return -1; // Move item without custom order to the end
      }
    }

    if (aSecondStructureIndex !== bSecondStructureIndex) {
      return aSecondStructureIndex - bSecondStructureIndex;
    }

    // If both items have the same custom order index and second structure index, compare them based on their original order
    return aRef.localeCompare(bRef);
  });

  const handleDeleteConfirmation = (item) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
      // Aquí puedes realizar la lógica para eliminar el elemento.
      // Puedes agregar tu propia implementación.

      deleteBook(item);
    } else {
      // El usuario seleccionó 'Cancelar', no hagas nada.
    }
  };

  return (
    <div className="font-rubik">
      <div className="container mx-auto">
        <div className="">
          {/* product  */}
          <div className=" px-4 md:px-0 mb-16">
            <div className="flex justify-between items-center mt-5">
              <div className="flex mx-0.5 mt-2 border border-gray-400 py-2 px-6 rounded mb-2">
                <SearchBar />
              </div>
              <div className="flex">
                <Link to={"../addbook"}>
                  <button
                    type="button"
                    className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
                    style={{
                      backgroundColor: mode === "dark" ? "rgb(46 49 55)" : "",
                      color: mode === "dark" ? "white" : "",
                    }}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      Agregar Ley{" "}
                      <FaRegFile size={20} title="Agregar Nueva Ley" />
                    </div>
                  </button>
                </Link>

                <Link to={"../addlib"}>
                  <button
                    type="button"
                    className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
                    style={{
                      backgroundColor: mode === "dark" ? "rgb(46 49 55)" : "",
                      color: mode === "dark" ? "white" : "",
                    }}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      Libreria <FaRegFile size={20} title="Agregar Libreria" />
                    </div>
                  </button>
                </Link>

                <Link to={"../addcategory"}>
                  <button
                    type="button"
                    className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
                    style={{
                      backgroundColor: mode === "dark" ? "rgb(46 49 55)" : "",
                      color: mode === "dark" ? "white" : "",
                    }}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      Agregar Categoria{" "}
                      <FaRegFolder size={20} title="Agregar Nueva Categoria" />
                    </div>
                  </button>
                </Link>

                <Link to={"../deletecategory"}>
                  <button
                    type="button"
                    className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
                    style={{
                      backgroundColor: mode === "dark" ? "rgb(46 49 55)" : "",
                      color: mode === "dark" ? "white" : "",
                    }}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      Categorias <FaListUl size={20} title="Categorias" />
                    </div>
                  </button>
                </Link>
              </div>
            </div>

            {filteredData.length === 0 ? (
              <div className="relative overflow-x-auto shadow-md ">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        No.
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ref
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Otro nombre que recibe la ley
                      </th>
                      <th scope="col" className="px-6 py-3">
                        No. De Decreto o Acuerdo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Título
                      </th>
                      <th scope="col" className="px-6 py-3 text-right">
                        Precio
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Acciones
                      </th>
                    </tr>
                  </thead>

                  {book.map((item, index) => {
                    const { title, price, law, alias, ref, extra } = item;
                    const isExtraVisible = showExtra[item.id]; // Check if extra should be shown
                    const { textColor, backgroundColor } =
                                getColorStyles(item.ref)
                    return (
                      <tbody key={item.id} className="">
                        <tr className="bg-gray-50 border-b  dark:border-clgris">
                          <td className="px-6 py-4 text-black ">{index + 1}</td>
                          <td className="px-6 py-4 text-black w-48 font-bold " style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}>{ref}</td>
                          
                          <td className="px-6 py-4 text-black " style={{ width: "5%" }}>{alias}</td>
                          <td className="px-6 py-4 text-black ">{law}</td>
                          <td
                            className="px-6 py-4 text-black "
                            onClick={() =>
                              setShowExtra({
                                ...showExtra,
                                [item.id]: !isExtraVisible,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {title}
                            {extra && (
                              <span onClick={(e) => e.stopPropagation()}>
                                {isExtraVisible ? " +" : " +"}
                              </span>
                            )}
                            {extra && isExtraVisible && (
                              <div className="text-sm  text-red-600">
                                {extra.split(".").map((line, index) => (
                                  <div key={index}>{line.trim()}</div>
                                ))}
                              </div>
                            )}
                          </td>

                          <td className="px-6 py-4 text-black text-right  w-28 text-xl ">
                            Q. {price}
                          </td>

                          <td className="px-6 py-4">
                            <div className=" flex gap-2">
                              <div className=" flex gap-2 cursor-pointer text-black ">
                                <div onClick={() => handleDeleteConfirmation(item)}>
                                  <FaTrash size={20} title="Eliminar" />
                                </div>

                                <Link to={"../updatebook"}>
                                  <FaRegEdit
                                    onClick={() => edithandle(item)}
                                    size={20}
                                    title="Editar"
                                  />
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            ) : (
              <div className="relative overflow-x-auto ">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  ">
                  <thead
                    className="text-xs border border-clgris text-white uppercase bg-clgris"
                    style={{
                      backgroundColor: mode === "dark" ? "rgb(46 49 55)" : "",
                      color: mode === "dark" ? "white" : "",
                    }}
                  >
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        No.
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ref
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Otro nombre que recibe la ley
                      </th>
                      <th scope="col" className="px-6 py-3">
                        No. De Decreto o Acuerdo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Título
                      </th>
                      <th scope="col" className="px-6 py-3 text-right">
                        Precio
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Acciones
                      </th>
                    </tr>
                  </thead>

                  {filteredData.map((item, index) => {
                    const { title, price, law, alias, ref, extra } = item;
                    const isExtraVisible = showExtra[item.id]; // Check if extra should be shown
                    const { textColor, backgroundColor } =
                                getColorStyles(item.ref)
                    return (
                      <tbody key={item.id} className="">
                        <tr className="bg-gray-50 border-b  dark:border-clgris">
                          <td className="px-6 py-4 text-black ">{index + 1}</td>
                          <td className="px-6 py-4 text-black w-48 font-bold " style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}>{ref}</td>
                          
                          <td className="px-6 py-4 text-black " style={{ width: "5%" }}>{alias}</td>
                          <td className="px-6 py-4 text-black ">{law}</td>
                          <td
                            className="px-6 py-4 text-black "
                            onClick={() =>
                              setShowExtra({
                                ...showExtra,
                                [item.id]: !isExtraVisible,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {title}
                            {extra && (
                              <span onClick={(e) => e.stopPropagation()}>
                                {isExtraVisible ? " +" : " +"}
                              </span>
                            )}
                            {extra && isExtraVisible && (
                              <div className="text-sm  text-red-600">
                                {extra.split(".").map((line, index) => (
                                  <div key={index}>{line.trim()}</div>
                                ))}
                              </div>
                            )}
                          </td>

                          <td className="px-6 py-4 text-black text-right  w-28 text-xl ">
                            Q. {price}
                          </td>

                          <td className="px-6 py-4">
                            <div className=" flex gap-2">
                              <div className=" flex gap-2 cursor-pointer text-black ">
                                <div onClick={() => handleDeleteConfirmation(item)}>
                                  <FaTrash size={20} title="Eliminar" />
                                </div>

                                <Link to={"../updatebook"}>
                                  <FaRegEdit
                                    onClick={() => edithandle(item)}
                                    size={20}
                                    title="Editar"
                                  />
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
