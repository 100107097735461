import React, { useContext, useEffect } from "react";
import MyContext from "../../../context/data/myContext";
import {
  FaPrint,
  FaTrash,
  FaRegEdit,
  FaBrush,
  FaEdit,
  FaRegCommentDots,
} from "react-icons/fa";
import { Link} from "react-router-dom";
import SearchBar3 from "../../../components/searchbar/SearchBar3";
import ModalArchive from "../../../components/modal/ModalArchive";

function Delivery() {
  const context = useContext(MyContext);
  const {
    delivery,
    deleteDelivery,
    edithandle3,
    edithandle4,
    getDeliveryData,
    filteredData3,
    Archive,
  } = context;

  useEffect(() => {
    getDeliveryData();
    // eslint-disable-next-line
  }, []);


  function getColorStyles(ref) {
    if (ref.includes("EN PROCESO")) {
      return {
        textColor: "black",
        backgroundColor: "yellow",
      };
    } else if (ref.includes("SUBIDO A ASANA")) {
      return {
        textColor: "white",
        backgroundColor: "orange",
      };
    } else if (ref.includes("FACTURADO")) {
      return {
        textColor: "white",
        backgroundColor: "blue",
      };
    } else if (ref.includes("FINALIZADO")) {
      return {
        textColor: "white",
        backgroundColor: "green",
      };
    } else if (ref.includes("CANCELADO")) {
      return {
        textColor: "white",
        backgroundColor: "red",
      };
    } else if (ref.includes("REEMBOLSADO")) {
      return {
        textColor: "white",
        backgroundColor: "red",
      };
    }

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  

  const handleDeleteConfirmation = (dev) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
      // Aquí puedes realizar la lógica para eliminar el elemento.
      // Puedes agregar tu propia implementación.

      deleteDelivery(dev);
    } else {
      // El usuario seleccionó 'Cancelar', no hagas nada.
    }
  };

  const sortedDelivery = delivery.slice().sort((a, b) => {
    const parseId = (id) => {
      const [year, month, count] = id.split("-").map(Number);
      return { year, month, count };
    };

    const { year: yearA, month: monthA, count: countA } = parseId(a.id);
    const { year: yearB, month: monthB, count: countB } = parseId(b.id);

    // Compare years first, then months, and finally counts
    if (yearB !== yearA) {
      return yearB - yearA; // Sort by year in descending order
    } else if (monthB !== monthA) {
      return monthB - monthA; // Sort by month in descending order
    } else {
      return countB - countA; // Sort by count in descending order
    }
  });


  return (
    <div className="relative overflow-x-auto  max-w-screen-2xl mx-auto my-4 shadow-md font-rubik ">
      <div className="">
        <div className=" flex mb-2">
          <div className="flex px-6  justify-start mx-0.5 border border-gray-400 py-2 rounded">
            <SearchBar3 />
          </div>
          <div className="flex-grow"></div>
          <Link to={"../asana"}>
            <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
              Subido a Asana
            </button>
          </Link>
          <Link to={"../can"}>
            <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
              Cancelado
            </button>
          </Link>
          <Link to={"../archive"}>
            <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
              Facturado
            </button>
          </Link>
        </div>

        {filteredData3.length === 0 ? (
          <table className="w-full text-center  mb-10">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">No. de Cot</th>
                <th className="px-6 py-3">Fecha</th>
                <th className="px-6 py-3">No. de Tel.</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3 w-56">Estado</th>
                <th className="px-6 py-3">Obs</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            {sortedDelivery.map((dev, index) => {
              const { textColor, backgroundColor } = getColorStyles(
                dev.process
              );
              const isDelivered = dev.process === "FACTURADO";
              return (
                <tbody key={dev.id}>
                  <tr className="bg-gray-50 border-b dark:border-gray-700">
                    <td className="px-6 py-3 text-black">
                      {dev.id && dev.id.substring(0, 4)}
                      {dev.id && dev.id.length > 4 && dev.id.substring(5)}{" "}
                      {/* Display characters after the hyphen */}
                    </td>
                    <td className="px-6 py-3 text-black">
                      {dev.cot3.cotId}-{dev.cot3.iniciales}
                    </td>
                    <td className="px-6 py-3 text-black">{dev.date}</td>
                    <td
                      className="px-6 py-3 text-black"
                      style={{ width: "10%" }}
                    >
                      {dev.cot3.phoneNumber}
                    </td>
                    <td className="px-6 py-3 text-black">{dev.cot3.name}</td>
                    <td className="px-6 py-3 text-black">
                      Q. {dev.cot3.grandTotal}
                    </td>
                    <td className="px-6 py-3 text-black"># {dev.factura}</td>
                    <td
                      className="px-6 py-3 text-black"
                      style={{
                        color: textColor,
                        backgroundColor: backgroundColor,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="mr-4">{dev.process}</div>

                      <Link to={"../updateprocess"}>
                        <FaRegEdit
                          onClick={() => edithandle4(dev)}
                          size={20}
                          title="Editar"
                        />
                      </Link>
                    </td>
                    <td>
                      <div className="inline-flex justify-center gap-2">
                        <FaRegCommentDots
                          size={20}
                          title="Observacion"
                          onClick={() => window.alert(dev.notes)}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3 text-black">
                      <div className=" inline-flex justify-center gap-2">
                        <div>
                          <FaTrash
                            size={20}
                            title="Eliminar"
                            onClick={() => handleDeleteConfirmation(dev)}
                          />
                        </div>

                        <Link to={`../updatedeliverybudget/${dev.id}`}>
                          <FaEdit size={20} title="Editar" />
                        </Link>

                        <Link to={"../updatedelivery"}>
                          <FaRegEdit
                            onClick={() => edithandle3(dev)}
                            size={20}
                            title="Editar"
                          />
                        </Link>

                        {dev.cot3.budgetItems &&
                          dev.cot3.budgetItems.every(
                            (item) => item.lawcolor !== ""
                          ) && (
                            <div>
                              <Link to={`../printdelivery/${dev.id}`}>
                                <FaPrint size={20} title="Imprimir" />
                              </Link>
                            </div>
                          )}

                        <Link to={`../updateperso/${dev.id}`}>
                          <FaBrush size={20} title="Personalizar" />
                        </Link>

                        

                        {isDelivered && (
                          <div>
                            <ModalArchive Archive={Archive} dev={dev} />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          <table className="w-full text-center mb-10">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">No. de Cot</th>
                <th className="px-6 py-3">Fecha</th>
                <th className="px-6 py-3">No. de Tel.</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3 w-60">Estado</th>
                <th className="px-6 py-3">Obs</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>
            {filteredData3.map((dev, index) => {
              const { textColor, backgroundColor } = getColorStyles(
                dev.process
              );
              const isDelivered = dev.process === "FACTURADO";
              return (
                <tbody key={dev.id}>
                  <tr className="bg-gray-50 border-b dark:border-gray-700">
                    <td className="px-6 py-3 text-black">
                      {dev.id && dev.id.substring(0, 4)}
                      {dev.id && dev.id.length > 4 && dev.id.substring(5)}{" "}
                      {/* Display characters after the hyphen */}
                    </td>
                    <td className="px-6 py-3 text-black">
                      {dev.cot3.cotId}-{dev.cot3.iniciales}
                    </td>
                    <td className="px-6 py-3 text-black">{dev.date}</td>
                    <td
                      className="px-6 py-3 text-black"
                      style={{ width: "10%" }}
                    >
                      {dev.cot3.phoneNumber}
                    </td>
                    <td className="px-6 py-3 text-black">{dev.cot3.name}</td>
                    <td className="px-6 py-3 text-black">
                      Q. {dev.cot3.grandTotal}
                    </td>
                    <td className="px-6 py-3 text-black"># {dev.factura}</td>
                    <td
                      className="px-6 py-3 text-black w-60"
                      style={{
                        color: textColor,
                        backgroundColor: backgroundColor,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="mr-4">{dev.process}</div>

                      <Link to={"../updateprocess"}>
                        <FaRegEdit
                          onClick={() => edithandle4(dev)}
                          size={20}
                          title="Editar"
                        />
                      </Link>
                    </td>
                    <td>
                      <div className="inline-flex justify-center gap-2">
                        <FaRegCommentDots
                          size={20}
                          title="Observacion"
                          onClick={() => window.alert(dev.notes)}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-3 text-black">
                      <div className=" inline-flex justify-center gap-2">
                        <div>
                          <FaTrash
                            size={20}
                            key={index}
                            onClick={() => handleDeleteConfirmation(dev)}
                          />
                        </div>

                        <Link to={`../updatedeliverybudget/${dev.id}`}>
                          <FaEdit size={20} title="Editar" />
                        </Link>

                        <Link to={"../updatedelivery"}>
                          <FaRegEdit
                            onClick={() => edithandle3(dev)}
                            size={20}
                            title="Editar"
                          />
                        </Link>

                        {dev.cot3.budgetItems &&
                          dev.cot3.budgetItems.every(
                            (item) => item.lawcolor !== ""
                          ) && (
                            <div>
                              <Link to={`../printdelivery/${dev.id}`}>
                                <FaPrint size={20} title="Imprimir" />
                              </Link>
                            </div>
                          )}

                        <Link to={`../updateperso/${dev.id}`}>
                          <FaBrush size={20} title="Personalizar" />
                        </Link>

                        

                        {isDelivered && (
                          <div>
                            <ModalArchive Archive={Archive} dev={dev} />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        )}
      </div>
    </div>
  );
}

export default Delivery;
