import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
import { toast } from "react-toastify";
import { fireDB } from "../../firebase/FirebaseConfig";
import {
  addDoc,
  collection,
  deleteDoc,
  onSnapshot,
  orderBy,
  query,
  doc,
  Timestamp,
  setDoc,
  getDocs,
  getDoc,
  where,
  writeBatch
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function MyState(props) {
  const [mode, setMode] = useState("light");

  const navigate = useNavigate ()

  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "rgb(17, 24, 39)";
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
    }
  };

  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  //Add Books

  const [books, setBooks] = useState({
    title: null,
    price: Number(-1),
    law: null,
    imageUrl: null,
    alias: null,
    ref: null,
    category: null,
    extra: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  // Update Budget

  const [bud, setBud] = useState({
    name: null,
    phoneNumber: Number(null),
    media: null,
  });

  // Update Delivery

  const [devi, setDevi] = useState({
    address: null,
    nit: null,
    email: null,
    pay: null,
    muni: null,
    dep: null,
    obs: null,
    send: null,
    eshop: null,
    datesend: null,
    notes: null,
  });

  // Update Process

  const [proc, setProc] = useState({
    process: null,
  });

  const [fac, setFact] = useState({
    factura: null,
    process: null
  });

  const [fac2, setFact2] = useState({
    factura: null,
    process: null
  });

  const [namep, setNamep] = useState({
    namepack: null,
    bcolor: null,
    tcolor:null,
  });


  /*
  //Add images
  
  const handleUpload = async (file) =>{
    const storageRef = ref(storage, `Imgs/${v4()}`)
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    return url
}

const handleSubmit = async (e) =>{
    e.preventDefault()
    try {
      const result = await handleUpload(file)
      console.log(result)
      setHope(result)
      toast.success("Guardado", {
        position: toast.POSITION.TOP_LEFT
      })

    } catch (error) {
      console.error(error)
    }
}
*/

  //Add book on Firebase

  const addBook = async () => {
    if ( books.price < 0 || books.ref == null) {
      return toast.error("Precio o Referencia inválidos", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
    setLoading(true);
    try {
      const bookRef = collection(fireDB, "books");
      await addDoc(bookRef, {
        title: books.title,
        price: Number(books.price),
        law: books.law,
        alias: books.alias,
        ref: books.ref,
        category: books.category,
        extra: books.extra,
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        time: Timestamp.now(),
      });
      toast.success("Agregado Excitosamente", {
        position: toast.POSITION.TOP_LEFT,
      });

      setTimeout(() => {
        navigate ("/dashboard")
      }, 800);

      getBookData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //Add Category

  const addCat = async () => {
    const bookRef = collection(fireDB, "categorys");
    await addDoc(bookRef, cats);
    toast.success("Agregado Excitosamente", {
      position: toast.POSITION.TOP_LEFT,
    });
    setTimeout(() => {
      navigate ("/dashboard")
    }, 800);
  };

  const [cats, setCats] = useState({
    categoria: null,
    paquete: null,
    subpaquete: null,
    bcolor: null,
    tcolor:null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  //Get Books

  const [book, setBook] = useState([]);
  
  useEffect(() => {
    const booksRef = collection(fireDB, 'books');

    const unsubscribe = onSnapshot(booksRef, (snapshot) => {
      const updatedBooks = [];

      snapshot.forEach((doc) => {
        const bookData = doc.data();
        const bookId = doc.id;

        updatedBooks.push({ ...bookData, id: bookId });
      });

      setBook(updatedBooks);
      localStorage.setItem('bookData', JSON.stringify(updatedBooks));
    });

    return () => unsubscribe();
  }, []);
  
  const getBookData = async () => {
    try {
      const storedData = localStorage.getItem('bookData');
  
      if (storedData) {
        setBook(JSON.parse(storedData));
      } else {
        const querySnapshot = await getDocs(collection(fireDB, 'books'));
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
  
        setBook(data);
  
        localStorage.setItem('bookData', JSON.stringify(data)); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };   

  //Back UP
  /*
  const [book, setBook] = useState([])

  const getBookData = async () => {

    const data = await getDocs(usersCollectionRef);
        setBook(data.docs.map((doc) => ({...doc.data(), id: doc.id })));
  }

  useEffect(() =>{
    getBookData()
  }, [])
  */

  // Get categorys

const [ca1, setCa1] = useState([]);

useEffect(() => {
  const categoriesRef = collection(fireDB, 'categorys');

  const unsubscribe = onSnapshot(categoriesRef, (snapshot) => {
    const updatedCategories = [];

    snapshot.forEach((doc) => {
      const categoryData = doc.data();
      const categoryId = doc.id;

      updatedCategories.push({ ...categoryData, id: categoryId });
    });

    setCa1(updatedCategories);
    localStorage.setItem('categoryData', JSON.stringify(updatedCategories));
  });

  return () => unsubscribe();
}, []);

const getCatData = async () => {
  try {
    const storedData = localStorage.getItem('categoryData');

    if (storedData) {
      setCa1(JSON.parse(storedData));
    } else {
      const querySnapshot = await getDocs(collection(fireDB, 'categorys'));
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setCa1(data);

      localStorage.setItem('categoryData', JSON.stringify(data));
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


  

  // update BOOK function

  const edithandle = (item) => {
    setBooks(item);
  };

  const updateBook = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "books", books.id), books);
      toast.success("Ley Actualizada", {
        position: toast.POSITION.TOP_LEFT,
      });
      setTimeout(() => {
        navigate ("/dashboard")
      });
      getBookData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // update CATEGORY function

  const edithandle7 = (cat) => {
    setCats(cat);
  };

  const updateCategory = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "categorys", cats.id), cats);
      toast.success("Categoria Actualizada", {
        position: toast.POSITION.TOP_LEFT,
      });
      setTimeout(() => {
        navigate ("/dashboard")
      });
      getCatData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  // update Delivery function

  const edithandle3 = (dev) => {
    setDevi(dev);
  };

  const updateDelivery = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "delivery", devi.id), devi);
      toast.success("Pedido Actualizado", {
        position: toast.POSITION.TOP_LEFT,
      });
      setTimeout(() => {
        navigate ("../dashboard/delivery")
      });
      getDeliveryData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // update Process function

  const edithandle4 = (dev) => {
    setProc(dev);
  };

  const updateProcess = async (dev) => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "delivery", proc.id), proc);
      toast.success("Estado Actualizado", {
        position: toast.POSITION.TOP_LEFT,
      });
  
      // Ejecutar funciones adicionales según el valor de process
      if (proc.process === "SUBIDO A ASANA") {
        await passAsana(dev);
      } else if (proc.process === "CANCELADO") {
        await passCan(dev);
      }
  
      // Otras acciones que desees realizar después de la actualización
  
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  // Functions complementary of updateProcess

  const passAsana = async (dev) => {
    // Llamada a la función para obtener el valor de asana
    await getIdAsana(dev);

    if (asana) {
      
      const archiInfo = {
        ...asana
      };

      try {
        await setDoc(doc(fireDB, "asana", dev.id), { ...archiInfo });
        toast.success("Pedido a Asana", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteDelivery(dev);
        
        setAsana("");
        navigate("../dashboard/asana");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede transferir.");
    }
  };

  const passCan = async (dev) => {
    // Llamada a la función para obtener el valor de can
    await getIdCan(dev);

    if (can) {
      
      const archiInfo = {
        ...can
      };

      try {
        await setDoc(doc(fireDB, "can", dev.id), { ...archiInfo });
        toast.success("Pedido a Cancelados", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteDelivery(dev);
        
        setCan("");
        navigate("../dashboard/can");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede transferir CAN.");
    }
  };



  //Pass



  const RestCanDev = async (dev) => {
    // Llamada a la función para obtener el valor de dev3
    await getIdResCan(dev);

    if (can2) {
      // Verifica que dev3 no sea vacío
      const archiInfo = {
        ...can2
      };

      try {
        await setDoc(doc(fireDB, "delivery", dev.id), { ...archiInfo });
        toast.success("Restaurado", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteCan(dev);
        await getDeliveryData();

        setCan2("")
        navigate("../dashboard/delivery");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede archivar.");
    }
  };


  // Funcion para pasar pedido a facturado


  const Archive = async (dev) => {
    // Llamada a la función para obtener el valor de dev3
    await getIdDelivery3(dev);

    if (dev3) {
      // Verifica que dev3 no sea vacío
      const archiInfo = {
        delivery: dev3,
        yearString: dev.yearString,
        monthNumber: dev.monthNumber,
        devID: dev.id,
      };

      try {
        await addDoc(collection(fireDB, "archive"), { ...archiInfo });
        toast.success("Pedido Trasladado", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteDelivery(dev);
        await getDeliveryData();

        setDev3("")
        navigate("../dashboard/archive");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede archivar.");
    }
  };


  // ASANA

  const Archive2 = async (dev) => {
    
    // Llamada a la función para obtener el valor de dev3
    await getIdAsana2(dev);

    if (asana2) {
      // Verifica que dev3 no sea vacío
      const archiInfo = {
        delivery: asana2,
        yearString: dev.yearString,
        monthNumber: dev.monthNumber,
        devID: dev.id,
      };

      try {
        await addDoc(collection(fireDB, "archive"), { ...archiInfo });
        toast.success("Pedido Trasladado", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteAsana(dev);
        await getAsanaData();

        
        setAsana2("")
        navigate("../dashboard/archive");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede archivar.");
    }
  };




  // update factura function

  const edithandle5 = (dev) => {
    setFact(dev);
  };

  const updateFactura = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "delivery", fac.id), fac);
      toast.success("Factura ingrresada", {
        position: toast.POSITION.TOP_LEFT,
      });
      
      setTimeout(() => {
        navigate ("../dashboard/delivery")
      });
      
      getDeliveryData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const edithandle9 = (dev) => {
    setFact2(dev);
  };

  const updateFactura2 = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "asana", fac2.id), fac2);
      toast.success("Factura ingrresada", {
        position: toast.POSITION.TOP_LEFT,
      });
      
      setTimeout(() => {
        navigate ("../dashboard/asana")
      });
      
      getAsanaData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // update factura NAMEPACK function

  const edithandle6 = (allorder) => {
    setNamep(allorder);
  };

  const updateNamepack = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "budget", namep.id), namep);
      
      
      setTimeout(() => {
        navigate ("../dashboard/budgets")
      });
      
      getOrderData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // update factura NAMEPACK budPendiente function

  const edithandle8 = (allorder) => {
    setNamep(allorder);
  };

  const updateNamepack2 = async () => {
    setLoading(true);
    try {
      await setDoc(doc(fireDB, "budPendiente", namep.id), namep);
      
      
      setTimeout(() => {
        navigate ("../dashboard/budPendiente")
      });
      
      getOrderData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  //Delete Laws

  const deleteBook = async (item) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "books", item.id));
      setLoading(false);
      getBookData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Budget

  const deleteBudget = async (allorder) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "budget", allorder.id));
      setLoading(false);
      getOrderData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  

  //Delete BudPen

  const deleteBudPen = async (allorder) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "budPendiente", allorder.id));
      setLoading(false);
      getPenData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete BudArchive

  const deleteBudArchive = async (allorder) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "budArchive", allorder.id));
      setLoading(false);
      getPenData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Budget Range

  const deleteBudgetRange = async (startNumber, endNumber) => {
    try {
      setLoading(true);
  
      // Obtener una referencia a la colección "budget"
      const budgetCollectionRef = collection(fireDB, "budArchive");
  
      // Realizar una consulta para obtener los documentos dentro del rango
      const querySnapshot = await getDocs(
        query(budgetCollectionRef, where("cotId", ">=", startNumber), where("cotId", "<=", endNumber))
      );
  
      // Eliminar cada documento dentro del rango
      const batch = writeBatch(fireDB);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
  
      // Commit del lote para aplicar los cambios
      await batch.commit();
  
      // Actualizar datos después de la eliminación
      getBudArchData();
  
      // Indicar que la operación ha finalizado correctamente
      setLoading(false);
    } catch (error) {
      // Manejar el error aquí (puedes mostrar un mensaje de error o realizar otras acciones necesarias)
      console.error("Error al eliminar documentos:", error);
  
      // Indicar que la operación ha finalizado con error
      setLoading(false);
    }
  };

  //Transfer by range from budget to budArchive

  const rangeArch = async (startNumber, endNumber) => {
    try {
      // Obtén la referencia de la colección "budget"
      const budgetCollection = collection(fireDB, 'budget');
  
      // Obtén los documentos en el rango especificado
      const q = query(
        budgetCollection,
        where('cotId', '>=', startNumber),
        where('cotId', '<=', endNumber)
      );
  
      const querySnapshot = await getDocs(q);
  
      // Recorre los documentos y muévelos a la colección "budArchive"
      const budArchiveCollection = collection(fireDB, 'budArchive');
      const batch = writeBatch(fireDB);
  
      querySnapshot.forEach(async (document) => {
        // Copia el documento a la nueva colección manteniendo el mismo ID
        const newDocRef = doc(budArchiveCollection, document.id);
        await setDoc(newDocRef, document.data());
      
        // Elimina el documento de la colección original si es necesario
        // (puedes comentar esta línea si no deseas eliminarlos)
        const oldDocRef = doc(budgetCollection, document.id);
        await deleteDoc(oldDocRef);
      });
  
      // Ejecuta la operación batch
      await batch.commit();
      await getOrderData();


      toast.success("Transferidas a Archivo", {
        position: toast.POSITION.TOP_LEFT,
      });
  
      console.log('Documentos transferidos exitosamente.');
    } catch (error) {
      console.error('Error al transferir documentos:', error);
    }
  };


 //Transfer by range from budPendiente to budArchive

 const rangeArch2 = async (startNumber, endNumber) => {
  try {
    // Obtén la referencia de la colección "budget"
    const budgetCollection = collection(fireDB, 'budPendiente');

    // Obtén los documentos en el rango especificado
    const q = query(
      budgetCollection,
      where('cotId', '>=', startNumber),
      where('cotId', '<=', endNumber)
    );

    const querySnapshot = await getDocs(q);

    // Recorre los documentos y muévelos a la colección "budArchive"
    const budArchiveCollection = collection(fireDB, 'budArchive');
    const batch = writeBatch(fireDB);

    querySnapshot.forEach(async (document) => {
      // Copia el documento a la nueva colección manteniendo el mismo ID
      const newDocRef = doc(budArchiveCollection, document.id);
      await setDoc(newDocRef, document.data());
    
      // Elimina el documento de la colección original si es necesario
      // (puedes comentar esta línea si no deseas eliminarlos)
      const oldDocRef = doc(budgetCollection, document.id);
      await deleteDoc(oldDocRef);
    });

    // Ejecuta la operación batch
    await batch.commit();
    await getPenData();


    toast.success("Transferidas a Archivo", {
      position: toast.POSITION.TOP_LEFT,
    });

    console.log('Documentos transferidos exitosamente.');
  } catch (error) {
    console.error('Error al transferir documentos:', error);
  }
};




  

  //Delete Delivery

  const deleteDelivery = async (dev) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "delivery", dev.id));
      setLoading(false);
      getDeliveryData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Asana

  const deleteAsana = async (dev) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "asana", dev.id));
      setLoading(false);
      getAsanaData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Can

  const deleteCan = async (dev) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "can", dev.id));
      setLoading(false);
      getCanData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Archive

  const deleteArchive = async (item) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "archive", item.id));
      setLoading(false);
      getArchData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  //Delete Category

  const deleteCategory = async (cat) => {
    try {
      setLoading(true);
      await deleteDoc(doc(fireDB, "categorys", cat.id));
      setLoading(false);
      getCatData();
    } catch (error) {
      // toast.success('Product Deleted Falied')
      setLoading(false);
    }
  };

  // get data from budgets from id to pass to be a delivery

  const [cot3, setCot3] = useState("");

  const getIdData3 = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budget", allorder.id));
      setCot3(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from budPen from id to pass to be a delivery

  const [cot4, setCot4] = useState("");

  const getIdData4 = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budPendiente", allorder.id));
      setCot4(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from delivery from id to pass to be a archive

  const [dev3, setDev3] = useState("");

  const getIdDelivery3 = async (dev) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "delivery", dev.id));
      setDev3(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from budget from id to pass to be a budPendiente

  const [bud1, setBud1] = useState("");

  const getIdBudPendiente = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budget", allorder.id));
      setBud1(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from budget from id to pass to be a budArchive

  const [bud3, setBud3] = useState("");

  const getIdBudArchive = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budget", allorder.id));
      setBud3(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from budpendiente from id to restore to budget

  const [bud2, setBud2] = useState("");

  const getIdResPendiente = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budPendiente", allorder.id));
      setBud2(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  // get data from can from id to restore to delivery

  const [can2, setCan2] = useState("");

  const getIdResCan = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "can", allorder.id));
      setCan2(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from budarchive from id to restore to budget

  const [bud4, setBud4] = useState("");

  const getIdResArchive = async (allorder) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budArchive", allorder.id));
      setBud4(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from delivery from id to pass to be a asana

  const [asana, setAsana] = useState("");

  const getIdAsana = async (dev) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "delivery", dev.id));
      setAsana(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from delivery from id to pass to be a asana

  const [asana2, setAsana2] = useState("");

  const getIdAsana2 = async (dev) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "asana", dev.id));
      setAsana2(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get data from delivery from id to pass to be a canceled

  const [can, setCan] = useState("");

  const getIdCan = async (dev) => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "delivery", dev.id));
      setCan(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  

  //it was for get the budget data
  const [order, setOrder] = useState([]);
  
  /*
  useEffect(() => {
    const booksRef = collection(fireDB, 'budget');

    const unsubscribe = onSnapshot(booksRef, (snapshot) => {
      const updatedOrder = [];

      snapshot.forEach((doc) => {
        const orderData = doc.data();
        const orderId = doc.id;

        updatedOrder.push({ ...orderData, id: orderId });
      });

      setOrder(updatedOrder);
      localStorage.setItem('budgetData', JSON.stringify(updatedOrder));
    });

    return () => unsubscribe();
  }, []);
  
  const getOrderData = async () => {
    try {
      const storedData = localStorage.getItem('budgetData');
  
      if (storedData) {
        setOrder(JSON.parse(storedData));
      } else {
        const querySnapshot = await getDocs(collection(fireDB, 'budget'));
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
  
        setOrder(data);
  
        localStorage.setItem('budgetData', JSON.stringify(data)); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };   
*/


  const getOrderData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "budget"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setOrder(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  

  // get delivery data

  const [delivery, setDelivery] = useState([]);


  const getDeliveryData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "delivery"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setDelivery(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get asana data

  const [devasana, setDevAsana] = useState([]);


  const getAsanaData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "asana"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setDevAsana(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get can data

  const [devcan, setDevCan] = useState([]);


  const getCanData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "can"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setDevCan(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
 

  

  // get archive data

  const [arch, setArch] = useState([]);

  const getArchData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "archive"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setArch(ordersArray);
      //console.log(arch)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Get BudPendiente data

  const [pen, setPen] = useState([]);


  const getPenData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "budPendiente"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setPen(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  // Get BudArchive data

  const [budArch, setbudArch] = useState([]);


  const getBudArchData = async () => {
    setLoading(true);
    try {
      const result = await getDocs(collection(fireDB, "budArchive"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push({ ...doc.data(), id: doc.id });
        setLoading(false);
      });
      setbudArch(ordersArray);
      //console.log(ordersArray)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  



  //

  const [catego, setCatego] = useState("");

  //Search Bar Filter

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    getBookData();
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = book.filter((value) => {
      // Check if each property is not null before calling toLowerCase()
      return (
        (value.title && value.title.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.law && value.law.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.alias && value.alias.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.category && value.category.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.ref && value.ref.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.extra && value.extra.toLowerCase().includes(searchWord.toLowerCase()))
      );
    });
  
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  
    console.log(newFilter);
  };
  //Delete of SearchBar

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };


  //Search Bar Filter for Budgets

  const [filteredData2, setFilteredData2] = useState([]);
  const [wordEntered2, setWordEntered2] = useState("");

  const handleFilter2 = (event) => {
    getOrderData();
    const searchWord = event.target.value;
    setWordEntered2(searchWord);
    const newFilter = order.filter((value) => {
      return (
        (value.cotId && value.cotId.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.iniciales && value.iniciales.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.name && value.name.toLowerCase().includes(searchWord.toLowerCase()))
      );
    });

    if (searchWord === "") {
      setFilteredData2([]);
    } else {
      setFilteredData2(newFilter);
    }

    console.log(newFilter);
  };

  //Delete of SearchBar

  const clearInput2 = () => {
    setFilteredData2([]);
    setWordEntered2("");
  };

  //Search Bar Filter for BudPendiente

  const [filteredData5, setFilteredData5] = useState([]);
  const [wordEntered5, setWordEntered5] = useState("");

  const handleFilter5 = (event) => {
    getPenData();
    const searchWord = event.target.value;
    setWordEntered5(searchWord);
    const newFilter = pen.filter((value) => {
      return (
        (value.cotId && value.cotId.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.iniciales && value.iniciales.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.name && value.name.toLowerCase().includes(searchWord.toLowerCase()))
      );
    });

    if (searchWord === "") {
      setFilteredData5([]);
    } else {
      setFilteredData5(newFilter);
    }

    console.log(newFilter);
  };

  //Delete of SearchBar

  const clearInput5 = () => {
    setFilteredData5([]);
    setWordEntered5("");
  };

  //Search Bar Filter for BudArchive

  const [filteredData6, setFilteredData6] = useState([]);
  const [wordEntered6, setWordEntered6] = useState("");

  const handleFilter6 = (event) => {
    getPenData();
    const searchWord = event.target.value;
    setWordEntered6(searchWord);
    const newFilter = budArch.filter((value) => {
      return (
        (value.cotId && value.cotId.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.iniciales && value.iniciales.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.name && value.name.toLowerCase().includes(searchWord.toLowerCase()))
      );
    });

    if (searchWord === "") {
      setFilteredData6([]);
    } else {
      setFilteredData6(newFilter);
    }

    console.log(newFilter);
  };

  //Delete of SearchBar

  const clearInput6 = () => {
    setFilteredData6([]);
    setWordEntered6("");
  };


  //Search Bar Filter for Delivery

  const [filteredData3, setFilteredData3] = useState([]);
  const [wordEntered3, setWordEntered3] = useState("");

  const handleFilter3 = (event) => {
    const searchWord = event.target.value.toLowerCase(); // Convert the search word to lowercase
    setWordEntered3(event.target.value);
  
    const newFilter = delivery.filter((value) => {
      const concatenatedText = `${value.yearString}${value.monthNumber}${value.id}`.toLowerCase();
      
      // Check if value.lastId is defined before accessing it
      const cleanedLastId = value.lastId ? value.lastId.replace(/-/g, '').toLowerCase() : '';
  
      // Split lastId into parts based on the hyphen
      const [numericPart, alphanumericPart] = (value.lastId || '').split('-').map(part => part.toLowerCase());
  
      const matches = [
        concatenatedText === searchWord,
        value.factura && value.factura.toString().toLowerCase().includes(searchWord),
        value.cot3.cotId && value.cot3.cotId.toLowerCase().includes(searchWord),
        value.date && value.date.toLowerCase().includes(searchWord),
        value.cot3.name && value.cot3.name.toLowerCase().includes(searchWord),
        value.cot3.phoneNumber && value.cot3.phoneNumber.toLowerCase().includes(searchWord),
        cleanedLastId === searchWord,
        numericPart === searchWord,
        alphanumericPart === searchWord
      ];
  
      return matches.some(Boolean);
    });
  
    setFilteredData3(searchWord === "" ? [] : newFilter);
    console.log(newFilter);
  };

  //Delete of SearchBar of delivery

  const clearInput3 = () => {
    setFilteredData3([]);
    setWordEntered3("");
  };

  //Search Bar Filter for Asana

  const [filteredData8, setFilteredData8] = useState([]);
  const [wordEntered8, setWordEntered8] = useState("");

  const handleFilter8 = (event) => {
    const searchWord = event.target.value.toLowerCase(); // Convert the search word to lowercase
    setWordEntered8(event.target.value);
  
    const newFilter = devasana.filter((value) => {
      const concatenatedText = `${value.yearString}${value.monthNumber}${value.id}`.toLowerCase();
      
      // Check if value.lastId is defined before accessing it
      const cleanedLastId = value.lastId ? value.lastId.replace(/-/g, '').toLowerCase() : '';
  
      // Split lastId into parts based on the hyphen
      const [numericPart, alphanumericPart] = (value.lastId || '').split('-').map(part => part.toLowerCase());
  
      const matches = [
        concatenatedText === searchWord,
        value.factura && value.factura.toString().toLowerCase().includes(searchWord),
        value.cot3.cotId && value.cot3.cotId.toLowerCase().includes(searchWord),
        value.date && value.date.toLowerCase().includes(searchWord),
        value.cot3.name && value.cot3.name.toLowerCase().includes(searchWord),
        value.cot3.phoneNumber && value.cot3.phoneNumber.toLowerCase().includes(searchWord),
        cleanedLastId === searchWord,
        numericPart === searchWord,
        alphanumericPart === searchWord
      ];
  
      return matches.some(Boolean);
    });
  
    setFilteredData8(searchWord === "" ? [] : newFilter);
    console.log(newFilter);
  };

  //Delete of SearchBar of Asana

  const clearInput8 = () => {
    setFilteredData8([]);
    setWordEntered8("");
  };



   //Search Bar Filter for Can

   const [filteredData9, setFilteredData9] = useState([]);
   const [wordEntered9, setWordEntered9] = useState("");
 
   const handleFilter9 = (event) => {
     const searchWord = event.target.value.toLowerCase(); // Convert the search word to lowercase
     setWordEntered9(event.target.value);
   
     const newFilter = devcan.filter((value) => {
       const concatenatedText = `${value.yearString}${value.monthNumber}${value.id}`.toLowerCase();
       
       // Check if value.lastId is defined before accessing it
       const cleanedLastId = value.lastId ? value.lastId.replace(/-/g, '').toLowerCase() : '';
   
       // Split lastId into parts based on the hyphen
       const [numericPart, alphanumericPart] = (value.lastId || '').split('-').map(part => part.toLowerCase());
   
       const matches = [
         concatenatedText === searchWord,
         value.factura && value.factura.toString().toLowerCase().includes(searchWord),
         value.cot3.cotId && value.cot3.cotId.toLowerCase().includes(searchWord),
         value.date && value.date.toLowerCase().includes(searchWord),
         value.cot3.name && value.cot3.name.toLowerCase().includes(searchWord),
         value.cot3.phoneNumber && value.cot3.phoneNumber.toLowerCase().includes(searchWord),
         cleanedLastId === searchWord,
         numericPart === searchWord,
         alphanumericPart === searchWord
       ];
   
       return matches.some(Boolean);
     });
   
     setFilteredData9(searchWord === "" ? [] : newFilter);
     console.log(newFilter);
   };
 
   //Delete of SearchBar of Asana
 
   const clearInput9 = () => {
     setFilteredData9([]);
     setWordEntered9("");
   };



  //Search Bar Filter for Factura

  const [filteredData4, setFilteredData4] = useState([]);
  const [wordEntered4, setWordEntered4] = useState("");

  const handleFilter4 = (event) => {
    getArchData();
    const searchWord = event.target.value;
    setWordEntered4(searchWord);
    const newFilter = arch.filter((value) => {
      const concatenatedText = `${value.yearString}${value.monthNumber}${value.devID}`.toLowerCase();
      return (
        (concatenatedText && concatenatedText === searchWord) ||
        (value.delivery.factura && value.delivery.factura.toString().toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.delivery.cot3.cotId && value.delivery.cot3.cotId.toLowerCase().includes(searchWord.toLowerCase())) ||
        (value.delivery.date && value.delivery.date.toLowerCase().includes(searchWord.toLowerCase()))
      );
    });

    if (searchWord === "") {
      setFilteredData4([]);
    } else {
      setFilteredData4(newFilter);
    }

    console.log(newFilter);
  };

  //Delete of SearchBar

  const clearInput4 = () => {
    setFilteredData4([]);
    setWordEntered4("");
  };

  return (
    <MyContext.Provider
      value={{
        mode,
        toggleMode,
        loading,
        setLoading,
        books,
        setBooks,
        addBook,
        book,
        edithandle,
        updateBook,
        deleteBook,
        order,
        catego,
        setCatego,
        cats,
        setCats,
        addCat,
        ca1,
        filteredData,
        setFilteredData,
        wordEntered,
        setWordEntered,
        handleFilter,
        file,
        setFile,
        deleteBudget,
        getOrderData,
        clearInput,
        cot3,
        getIdData3,
        getDeliveryData,
        delivery,
        deleteDelivery,
        deleteCategory,
        bud,
        setBud,
        devi,
        setDevi,
        updateDelivery,
        edithandle3,
        proc,
        setProc,
        updateProcess,
        edithandle4,
        arch,
        getArchData,
        deleteArchive,
        getBookData,
        getCatData,
        fac,
        setFact,
        updateFactura,
        edithandle5,
        namep,
        setNamep,
        edithandle6,
        updateNamepack,
        filteredData2,
        wordEntered2,
        handleFilter2,
        clearInput2,
        filteredData3,
        wordEntered3,
        handleFilter3,
        clearInput3,
        dev3,
        setDev3,
        getIdDelivery3,
        edithandle7,
        updateCategory,
        setCa1,
        filteredData4,
        wordEntered4,
        handleFilter4,
        clearInput4,
        deleteBudgetRange,
        getIdBudPendiente,
        bud1,
        setBud1,
        getPenData,
        pen,
        filteredData5,
        wordEntered5,
        clearInput5,
        handleFilter5,
        getIdData4,
        cot4,
        deleteBudPen,
        edithandle8,
        updateNamepack2,
        getIdResPendiente,
        bud2,
        getIdBudArchive,
        bud3,
        getBudArchData,
        budArch,
        filteredData6,
        wordEntered6,
        clearInput6,
        handleFilter6,
        deleteBudArchive,
        getIdResArchive,
        bud4,
        rangeArch,
        rangeArch2,
        asana,
        getIdAsana,
        can,
        getIdCan,
        passAsana,
        passCan,
        getAsanaData,
        devasana,
        handleFilter8,
        clearInput8,
        filteredData8,
        wordEntered8,
        deleteAsana,
        edithandle9,
        updateFactura2,
        fac2,
        setFact2,
        getIdAsana2,
        asana2,
        setAsana2,
        devcan,
        getCanData,
        deleteCan,
        handleFilter9,
        clearInput9,
        wordEntered9,
        filteredData9,
        getIdResCan,
        can2,
        RestCanDev,
        Archive,
        Archive2
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
