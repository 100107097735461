import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../context/data/myContext";
import { useDispatch, useSelector } from "react-redux";
import { addToBudget, deleteFromBudget, clearCart } from "../../redux/budgetSlice";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { FaEraser } from "react-icons/fa";
import SearchBar from "../searchbar/SearchBar";

function BudgetBook() {
  const context = useContext(MyContext);
  const { book, filteredData, getBookData, getCatData, ca1 } = context;

  useEffect(() => {
    getBookData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCatData();
    // eslint-disable-next-line
  }, []);

  // Add a state variable to track whether the "Categoria" button has been clicked
  const [isCategoriaButtonClicked, setIsCategoriaButtonClicked] =
    useState(false);

  // ... existing code

  // Function to handle the "Categoria" button click
  const handleCategoriaButtonClick = () => {
    setIsCategoriaButtonClicked(!isCategoriaButtonClicked);
  };

  const dispatch = useDispatch();
  const budgetItems = useSelector((state) => state.budget);

  const [showExtra, setShowExtra] = useState({}); // State to track whether to show extra text

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryItems, setSelectedCategoryItems] = useState([]); // Almacenar productos de la categoría

  const addBudget = () => {
    // Agregar todos los productos de la categoría seleccionada al presupuesto
    const categoryProducts = book
      .filter((item) => item.category.includes(selectedCategory))
      .map((product) => ({ ...product, origen: "Paquete" })); // Agregar el parámetro 'origen' a cada producto

    categoryProducts.forEach((product) => {
      dispatch(addToBudget(product));
    });
  };

  const addBudgetsolo = (product) => {
    // Create a copy of the product with the "origen" field added
    const productWithOrigen = { ...product, origen: "" };

    // Dispatch the action to add the product to the budget
    dispatch(addToBudget(productWithOrigen));
  };

  const deleteCart = (item) => {
    dispatch(deleteFromBudget(item));
  };

  const deleteCart2 = (item) => {
    dispatch(clearCart(item));
  };

  const checkProductInCart = (product) => {
    return budgetItems.some((item) => item.id === product.id);
  };

  useEffect(() => {
    localStorage.setItem("budget", JSON.stringify(budgetItems));
  }, [budgetItems]);

  useEffect(() => {
    if (selectedCategory) {
      const categoryProducts = book.filter(
        (item) => item.category === selectedCategory
      );
      setSelectedCategoryItems(categoryProducts);
    } else {
      setSelectedCategoryItems([]); // Limpiar la selección si no hay categoría seleccionada
    }
  }, [selectedCategory, book]);

  // Define the custom order
  const customOrder = [
    "WOLF",
    "PLUS",
    "DLXO",
    "DLXC",
    "WOLFF",
    "CIV",
    "MERC",
    "NOT",
    "ADMIN",
    "PEN",
    "LABO",
    "CONV",
  ];

  // Sort the book array based on the custom order and the second structure of item.ref
  book.sort((a, b) => {
    // Function to extract the second structure from item.ref
    const extractSecondStructure = (ref) => {
      const parts = ref.split("-");
      if (parts.length >= 2) {
        return parts[1].trim();
      }
      return ref;
    };

    // Define a custom order for the second structure
    const customOrderSecondStructure = [
      "CONST",
      "ORG",
      "CIV",
      "MERC",
      "NOT",
      "ADMIN",
      "PEN",
      "LABO",
    ];

    const aRef = a.ref;
    const bRef = b.ref;
    const aSecondStructure = extractSecondStructure(aRef);
    const bSecondStructure = extractSecondStructure(bRef);

    // Compare items based on the custom order of the first structure
    const aCustomOrderIndex = customOrder.indexOf(aRef.split("-")[0]);
    const bCustomOrderIndex = customOrder.indexOf(bRef.split("-")[0]);

    // Get the index in the custom order for the second structure
    const aSecondStructureIndex =
      customOrderSecondStructure.indexOf(aSecondStructure);
    const bSecondStructureIndex =
      customOrderSecondStructure.indexOf(bSecondStructure);

    // Compare items based on their custom order index and then by the second structure
    if (aCustomOrderIndex !== -1 && bCustomOrderIndex !== -1) {
      if (aCustomOrderIndex !== bCustomOrderIndex) {
        return aCustomOrderIndex - bCustomOrderIndex;
      }
    } else {
      // If one or both items don't have a custom order index, move them to the end
      if (aCustomOrderIndex === -1 && bCustomOrderIndex === -1) {
        // If both don't have custom order indices, compare by second structure
        if (aSecondStructureIndex !== bSecondStructureIndex) {
          return aSecondStructureIndex - bSecondStructureIndex;
        }
        // If both don't have custom order indices and second structure indices are equal, compare by original order
        return aRef.localeCompare(bRef);
      } else if (aCustomOrderIndex === -1) {
        return 1; // Move item without custom order to the end
      } else {
        return -1; // Move item without custom order to the end
      }
    }

    if (aSecondStructureIndex !== bSecondStructureIndex) {
      return aSecondStructureIndex - bSecondStructureIndex;
    }

    // If both items have the same custom order index and second structure index, compare them based on their original order
    return aRef.localeCompare(bRef);
  });

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto mt-5 mb-20">
        <div className="w-full mb-2">
          <div className="flex justify-between items-center">
            <div className="inline-block">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
                Pendientes por validar: {budgetItems.length}
              </h1>
              <div className="bg-clgris rounded"></div>
            </div>

            <div className="inline-block mx-5 mt-2 border border-black py-2 px-6 rounded">
              <SearchBar />
            </div>

            <div className="flex">

            <div className="text-center ml-1 py-2">
                <button
                  onClick={deleteCart2}
                  className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded"
                >
                  Borrar Selec.
                </button>
              </div>
              <div className="text-center ml-1 py-2">
                {/* Add a button for "Categoria" and handle its click event */}
                <button
                  onClick={handleCategoriaButtonClick}
                  className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded"
                >
                  Ver Categoria
                </button>
              </div>

              <div className="text-center ml-1 py-5">
              <Link
                  to={"../budgetbookcat"}
                  className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded"
                >
                  Seleccion por Paquete
                </Link>
              </div>

              <div className="text-center ml-1 py-5">
                <Link
                  to={"../budget"}
                  className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded"
                >
                  Validar
                </Link>
              </div>

              <div className="text-center ml-1 py-5">
                <Link
                  to={"../budgets"}
                  className="bg-clgris hover-bg-clgris text-white py-2.5 px-6 rounded"
                >
                  Regresar
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="">

          {filteredData.length === 0 ? (
            <div>
              <table className="w-full text-sm text-center">
                <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
                  <tr>
                    <th className="px-6 py-3">REF</th>
                    <th className="px-6 py-3">Titulo</th>
                    <th className="px-6 py-3 text-right">Precio</th>
                    {isCategoriaButtonClicked && (
                      <th className="px-6 py-3">Categorias</th>
                    )}

                    <th className="px-6 py-3">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {book.map((item) => {
                    const isProductInCart = checkProductInCart(item);
                    const { title, extra } = item;
                    const isExtraVisible = showExtra[item.id]; // Check if extra should be shown

                    // Verificar si la categoría está vacía o si la categoría del elemento contiene la categoría seleccionada
                    if (
                      !selectedCategory ||
                      item.category.includes(selectedCategory)
                    ) {
                      return (
                        <tr
                          className="bg-gray-50 border-b  dark:border-clgris  h-max"
                          key={item.id}
                        >
                          <td className="px-6 py-4 text-black w-48">
                            {item.ref}
                          </td>
                          <td
                            className="px-6 py-4 text-black "
                            onClick={() =>
                              setShowExtra({
                                ...showExtra,
                                [item.id]: !isExtraVisible,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {title}
                            {extra && (
                              <span onClick={(e) => e.stopPropagation()}>
                                {isExtraVisible ? " +" : " +"}
                              </span>
                            )}
                            {extra && isExtraVisible && (
                              <div className="text-sm text-red-600">
                                {extra.split(".").map((line, index) => (
                                  <div key={index}>{line.trim()}</div>
                                ))}
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4 text-black text-xl text-right  w-28 ">
                            Q {item.price}
                          </td>
                          {isCategoriaButtonClicked && (
                            <td className="px-6 py-4 text-black ">
                              {item.category}
                            </td>
                          )}
                          <td className="px-6 py-4 text-black text-center ">
                            <button
                              style={{
                                color: isProductInCart ? "red" : "#09f",
                              }}
                              onClick={() => {
                                isProductInCart
                                  ? deleteCart(item)
                                  : addBudgetsolo(item);
                              }}
                            >
                              {isProductInCart ? <FaEraser /> : <FaCheck />}
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <table className="w-full text-sm text-center">
                <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
                  <tr>
                    <th className="px-6 py-3">REF</th>
                    <th className="px-6 py-3">Titulo</th>
                    <th className="px-6 py-3 text-right">Precio</th>
                    {isCategoriaButtonClicked && (
                      <th className="px-6 py-3">Categorias</th>
                    )}
                    <th className="px-6 py-3">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => {
                    const isProductInCart = checkProductInCart(item);
                    const { title, extra } = item;
                    const isExtraVisible = showExtra[item.id]; // Check if extra should be shown
                    return (
                      <tr className="bg-gray-50 border-b  dark:border-clgris">
                        <td className="px-6 py-4 text-black w-48">
                          {item.ref}
                        </td>

                        <td
                            className="px-6 py-4 text-black "
                            onClick={() =>
                              setShowExtra({
                                ...showExtra,
                                [item.id]: !isExtraVisible,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {title}
                            {extra && (
                              <span onClick={(e) => e.stopPropagation()}>
                                {isExtraVisible ? " +" : " +"}
                              </span>
                            )}
                            {extra && isExtraVisible && (
                              <div className="text-sm text-red-600">
                                {extra.split(".").map((line, index) => (
                                  <div key={index}>{line.trim()}</div>
                                ))}
                              </div>
                            )}
                          </td>

                        <td className="px-6 py-4 text-black text-xl text-right  w-28">
                          Q {item.price}
                        </td>
                        {isCategoriaButtonClicked && (
                          <td className="px-6 py-4 text-black ">
                            {item.category}
                          </td>
                        )}
                        <td className="px-6 py-4 text-black ">
                          <button
                            style={{ color: isProductInCart ? "red" : "#09f" }}
                            onClick={() => {
                              isProductInCart
                                ? deleteCart(item)
                                : addBudgetsolo(item);
                            }}
                          >
                            {isProductInCart ? <FaEraser /> : <FaCheck />}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BudgetBook;
