import React, { useContext } from 'react'
import MyContext from '../../context/data/myContext';
import { Link } from 'react-router-dom';

function UpdateNamepack2() {
    const context = useContext(MyContext);
    const {  namep, setNamep, updateNamepack2 } = context;
    return (
      <div className="p-0 sm:p-6">
        <div className=" mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg">
          <h1 className="text-center text-xl mb-4">Nombre del Paquete</h1>
          <div className="flex flex-col mb-3">
          <input
            type="text"
            value={namep.namepack}
            onChange={(e) => setNamep({ ...namep, namepack: e.target.value })}
            name="namep"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Nombre del Paquete"
            style={{ width: "90%" }}
          />
          </div>

          <div className="flex flex-col mb-3">
          <label>Seleccione color de Fondo</label>
          <div className="flex items-center">
          <input
            type="color"
            id="bcolor"
            name="bcolor"
            value={namep.bcolor}
            onChange={(e) => setNamep({ ...namep, bcolor: e.target.value })}
            className="mr-2"
          />
          <input
            type="text"
            value={namep.bcolor}
            onChange={(e) => setNamep({ ...namep, bcolor: e.target.value })}
            placeholder={namep.bcolor}
            className="p-2 border border-gray-300 rounded-md"
          />
          </div>
        </div>


        <div className="flex flex-col mb-3">
          <label>Seleccione color de Texto</label>
          <div className="flex items-center">
            <input
              type="color"
              id="tcolor"
              name="tcolor"
              value={namep.tcolor}
              onChange={(e) => setNamep({ ...namep, tcolor: e.target.value })}
              className="mr-2"
            />
            <input
              type="text"
              value={namep.tcolor}
              onChange={(e) => setNamep({ ...namep, tcolor: e.target.value })}
              placeholder={namep.tcolor}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div style={{ marginTop: '20px', }}>
          <div
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: namep.bcolor,
              color: namep.tcolor,
              border: '1px solid #ccc',
              borderRadius: '4px',
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {namep.namepack}
          </div>
        </div>
          
  
          <div className=" flex justify-center mb-3">
          <Link
              to="../budgets"
              className="bg-clgris  hover:bg-clgris text-white font-bold py-2.5 px-4  rounded m-2"
            >
              Regresar
            </Link>
            <button
              onClick={updateNamepack2}
              className="bg-clgris  hover:bg-clgris text-white font-bold py-2 px-4 rounded m-2"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    );
}

export default UpdateNamepack2