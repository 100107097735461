import React, { useContext, useEffect } from "react";
import MyContext from "../../../context/data/myContext";
import {
  FaPrint,
  FaTrash,
  FaCashRegister,
  FaRegCommentDots,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ModalArchive2 from "../../../components/modal/ModalArchive2";
import SearchBar8 from "../../../components/searchbar/SearchBar8";

function Asana() {

    const context = useContext(MyContext);
  const {
    devasana,
    deleteAsana,
    getAsanaData,
    edithandle9,
    filteredData8,
    Archive2
  } = context;

  useEffect(() => {
    getAsanaData();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  function getColorStyles(ref) {
    if (ref.includes("EN PROCESO")) {
      return {
        textColor: "black",
        backgroundColor: "yellow",
      };
    } else if (ref.includes("SUBIDO A ASANA")) {
      return {
        textColor: "white",
        backgroundColor: "orange",
      };
    } else if (ref.includes("FACTURADO")) {
      return {
        textColor: "white",
        backgroundColor: "blue",
      };
    } else if (ref.includes("FINALIZADO")) {
      return {
        textColor: "white",
        backgroundColor: "green",
      };
    } else if (ref.includes("CANCELADO")) {
      return {
        textColor: "white",
        backgroundColor: "red",
      };
    } else if (ref.includes("REEMBOLSADO")) {
      return {
        textColor: "white",
        backgroundColor: "red",
      };
    }

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  

  const handleDeleteConfirmation = (dev) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
      // Aquí puedes realizar la lógica para eliminar el elemento.
      // Puedes agregar tu propia implementación.

      deleteAsana(dev);
    } else {
      // El usuario seleccionó 'Cancelar', no hagas nada.
    }
  };

  const sortedDelivery = devasana.slice().sort((a, b) => {
    const parseId = (id) => {
      const [year, month, count] = id.split("-").map(Number);
      return { year, month, count };
    };

    const { year: yearA, month: monthA, count: countA } = parseId(a.id);
    const { year: yearB, month: monthB, count: countB } = parseId(b.id);

    // Compare years first, then months, and finally counts
    if (yearB !== yearA) {
      return yearB - yearA; // Sort by year in descending order
    } else if (monthB !== monthA) {
      return monthB - monthA; // Sort by month in descending order
    } else {
      return countB - countA; // Sort by count in descending order
    }
  });

  return (
    <div className="relative overflow-x-auto  max-w-screen-2xl mx-auto my-4 shadow-md font-rubik ">
    <div className="">
    <div>
            <h1 className=" text-center text-2xl">SUBIDO A ASANA</h1>
        </div>
      <div className=" flex mb-2">
        <div className="flex px-6  justify-start mx-0.5 border border-gray-400 py-2 rounded">
          <SearchBar8 />
        </div>
        <div className="flex-grow"></div>
        <Link to={"../can"}>
          <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
          Cancelado
          </button>
        </Link>
        <Link to={"../archive"}>
          <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
          Facturado
          </button>
        </Link>
        <Link to={"../delivery"}>
          <button className="bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            Regresar
          </button>
        </Link>
      </div>

      {filteredData8.length === 0 ? (
        <table className="w-full text-center  mb-10">
          <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
            <tr>
              <th className="px-6 py-3">No. de Pedido</th>
              <th className="px-6 py-3">No. de Cot</th>
              <th className="px-6 py-3">Fecha</th>
              <th className="px-6 py-3">No. de Tel.</th>
              <th className="px-6 py-3">Nombre</th>
              <th className="px-6 py-3">Precio Total</th>
              <th className="px-6 py-3">Pos File</th>
              <th className="px-6 py-3 w-56">Estado</th>
              <th className="px-6 py-3">Obs</th>
              <th className="px-6 py-3">Acciones</th>
            </tr>
          </thead>
          {sortedDelivery.map((dev, index) => {
            const { textColor, backgroundColor } = getColorStyles(
              dev.process
            );
            const isDelivered = dev.process === "FACTURADO";
            return (
              <tbody key={dev.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {dev.id && dev.id.substring(0, 4)}
                    {dev.id && dev.id.length > 4 && dev.id.substring(5)}{" "}
                    {/* Display characters after the hyphen */}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {dev.cot3.cotId}-{dev.cot3.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">{dev.date}</td>
                  <td
                    className="px-6 py-3 text-black"
                    style={{ width: "10%" }}
                  >
                    {dev.cot3.phoneNumber}
                  </td>
                  <td className="px-6 py-3 text-black">{dev.cot3.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {dev.cot3.grandTotal}
                  </td>
                  <td className="px-6 py-3 text-black"># {dev.factura}</td>
                  <td
                    className="px-6 py-3 text-black"
                    style={{
                      color: textColor,
                      backgroundColor: backgroundColor,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="mr-4">{dev.process}</div>

                   
                  </td>
                  <td>
                    <div className="inline-flex justify-center gap-2">
                      <FaRegCommentDots
                        size={20}
                        title="Observacion"
                        onClick={() => window.alert(dev.notes)}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-3 text-black">
                    <div className=" inline-flex justify-center gap-2">
                      <div>
                        <FaTrash
                          size={20}
                          title="Eliminar"
                          onClick={() => handleDeleteConfirmation(dev)}
                        />
                      </div>


                      
                          <div>
                            <Link to={`../printdevasana/${dev.id}`}>
                              <FaPrint size={20} title="Imprimir" />
                            </Link>
                          </div>
                        


                      <Link to={"../updatefactura2"}>
                        <FaCashRegister
                          onClick={() => edithandle9(dev)}
                          size={20}
                          title="Facturar"
                        />
                      </Link>

                      {isDelivered && (
                        <div>
                          <ModalArchive2 Archive={Archive2} dev={dev} />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      ) : (
        <table className="w-full text-center mb-10">
          <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
            <tr>
              <th className="px-6 py-3">No. de Pedido</th>
              <th className="px-6 py-3">No. de Cot</th>
              <th className="px-6 py-3">Fecha</th>
              <th className="px-6 py-3">No. de Tel.</th>
              <th className="px-6 py-3">Nombre</th>
              <th className="px-6 py-3">Precio Total</th>
              <th className="px-6 py-3">Pos File</th>
              <th className="px-6 py-3 w-60">Estado</th>
              <th className="px-6 py-3">Obs</th>
              <th className="px-6 py-3">Acciones</th>
            </tr>
          </thead>
          {filteredData8.map((dev, index) => {
            const { textColor, backgroundColor } = getColorStyles(
              dev.process
            );
            const isDelivered = dev.process === "FACTURADO";
            return (
              <tbody key={dev.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {dev.id && dev.id.substring(0, 4)}
                    {dev.id && dev.id.length > 4 && dev.id.substring(5)}{" "}
                    {/* Display characters after the hyphen */}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {dev.cot3.cotId}-{dev.cot3.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">{dev.date}</td>
                  <td
                    className="px-6 py-3 text-black"
                    style={{ width: "10%" }}
                  >
                    {dev.cot3.phoneNumber}
                  </td>
                  <td className="px-6 py-3 text-black">{dev.cot3.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {dev.cot3.grandTotal}
                  </td>
                  <td className="px-6 py-3 text-black"># {dev.factura}</td>
                  <td
                    className="px-6 py-3 text-black w-60"
                    style={{
                      color: textColor,
                      backgroundColor: backgroundColor,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="mr-4">{dev.process}</div>

                    
                  </td>
                  <td>
                    <div className="inline-flex justify-center gap-2">
                      <FaRegCommentDots
                        size={20}
                        title="Observacion"
                        onClick={() => window.alert(dev.notes)}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-3 text-black">
                    <div className=" inline-flex justify-center gap-2">
                      <div>
                        <FaTrash
                          size={20}
                          key={index}
                          onClick={() => handleDeleteConfirmation(dev)}
                        />
                      </div>



                     
                          <div>
                            <Link to={`../printdevasana/${dev.id}`}>
                              <FaPrint size={20} title="Imprimir" />
                            </Link>
                          </div>
                        



                      <Link to={"../updatefactura2"}>
                        <FaCashRegister
                          onClick={() => edithandle9(dev)}
                          size={20}
                          title="Facturar"
                        />
                      </Link>

                      {isDelivered && (
                        <div>
                          <ModalArchive2 Archive={Archive2} dev={dev} />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      )}
    </div>
  </div>
  )
}

export default Asana