import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import MyContext from "../../context/data/myContext";
import { toast } from "react-toastify";

const UpdatePerso = () => {
  const context = useContext(MyContext);
  const { getDeliveryData } = context;

  const navigate = useNavigate();
  const { id } = useParams();
  const [bud, setBud] = useState(null);
  const [newname, setNewname] = useState("");
  const [namepack, setNamepack] = useState("");
  const [bcolor, setBcolor] = useState("");
  const [tcolor, setTcolor] = useState("");
  const [newnameport, setNewnameport] = useState("");
  const [newlogo, setNewlogo] = useState("");
  const [editedBudget, setEditedBudget] = useState([
    {
      quantity: Number(""),
      alias: "",
      category: "",
      date: "",
      id: "",
      law: "",
      price: "",
      ref: "",
      time: "",
      title: "",
      lawcolor: "",
      origen: "",
    },
  ]);
  
  
  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await getDoc(doc(fireDB, "delivery", id));
      if (userDoc.exists()) {
        setBud(userDoc.data());
        setNewname(userDoc.data().cot3.name);
        setNamepack(userDoc.data().cot3.namepack);
        setTcolor(userDoc.data().cot3.tcolor);
        setBcolor(userDoc.data().cot3.bcolor);
        setNewnameport(userDoc.data().cot3.nameport);
        setNewlogo(userDoc.data().cot3.logo);
        setEditedBudget(
          userDoc.data().cot3.budgetItems.map((item) => ({
            quantity: Number(item.quantity),
            alias: item.alias,
            category: item.category,
            date: item.date,
            id: item.id,
            law: item.law,
            price: item.price,
            ref: item.ref,
            time: item.time,
            title: item.title,
            lawcolor: item.lawcolor || "",
            origen: item.origen || "",
          }))
        );
      }
    };

    fetchUser();
  }, [id]);

  const handleUpdate = async () => {
    // Check if newlogo or newnameport is empty
  if (!newlogo || !newnameport) {
    return toast.error("Llenar todos los campos", {
      position: toast.POSITION.TOP_LEFT,
    });
  }

  // Check if any lawcolor in editedBudget is empty
  const hasEmptyLawColor = editedBudget.some((service) => !service.lawcolor);
  if (hasEmptyLawColor) {
    return toast.error("Llenar todos los campos", {
      position: toast.POSITION.TOP_LEFT,
    });
  }


    const updatedBudget = {
      ...bud,
      cot3: {
        ...budgetItems,
        date: bud.cot3.date,
        grandTotal: bud.cot3.grandTotal,
        iniciales: bud.cot3.iniciales,
        media: bud.cot3.media,
        name: newname,
        newshipping: bud.cot3.newshipping,
        phoneNumber: bud.cot3.phoneNumber,
        nameport: newnameport,
        logo: newlogo,
        discountPercentage: bud.cot3.discountPercentage,
        cotId: bud.cot3.cotId,
        namepack: namepack,
        bcolor: bcolor,
        tcolor: tcolor
      },
    };


    try {
      await updateDoc(doc(fireDB, "delivery", id), updatedBudget);
      console.log("Actualizado");

      await getDeliveryData();

      navigate("../delivery");
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };

  const handleServiceChange2 = (index, value) => {
    const updatedServices = [...editedBudget];
    updatedServices[index].lawcolor = value;
    setEditedBudget(updatedServices);
  };

  const budgetItems = {
    budgetItems: editedBudget.map((service) => ({
      quantity: Number(service.quantity),
      alias: service.alias,
      category: service.category,
      date: service.date,
      id: service.id,
      law: service.law,
      price: service.price,
      ref: service.ref,
      time: service.time,
      title: service.title,
      lawcolor: service.lawcolor || "",
      origen: service.origen || "",
    })),
  };


  function getColorStyles(ref) {
    if (ref.includes("WOLFF")) {
      return {
        textColor: "#4EA807",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("PLUS")) {
      return {
        textColor: "#DBB600",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("DLXW")) {
      return {
        textColor: "white",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("DLX")) {
      return {
        textColor: " #DBB600",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("WOLF")) {
      return {
        textColor: "white",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("CIV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFF00",
      };
    } else if (ref.includes("MERC")) {
      return {
        textColor: "black",
        backgroundColor: "#66FF66",
      };
    } else if (ref.includes("NOT")) {
      return {
        textColor: "black",
        backgroundColor: "#FF9900",
      };
    } else if (ref.includes("ADMIN")) {
      return {
        textColor: "black",
        backgroundColor: "#00B0F0",
      };
    } else if (ref.includes("PEN")) {
      return {
        textColor: "white",
        backgroundColor: "#FF0000",
      };
    } else if (ref.includes("LABO")) {
      return {
        textColor: "black",
        backgroundColor: "#DDC88F",
      };
    } else if (ref.includes("CONV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFFC7",
      };
    } else if (ref.includes("LIB")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("PROTOCOLO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFI")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TN")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    }
    // Agrega más condiciones según tus necesidades

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  return (
    <div>
      <div className=" p-0 sm:p-6">
        <div className=" mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg ">
          <div className="">
            <h1 className="text-center text-xl mb-4">Personalizacion de Leyes</h1>
          </div>

          <div>
            <label
              htmlFor="newnameport"
              className="block mb-2 text-sm font-medium text-black"
            >
              Nombre en la Portada: {newnameport}
            </label>
            <input
              type="text"
              value={newnameport}
              onChange={(e) => setNewnameport(e.target.value)}
              name="newnameport"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div>
            <label
              htmlFor="logo"
              className="block mb-2 text-sm font-medium text-black"
            >
              Logo: {newlogo}
            </label>
            <input
              type="text"
              value={newlogo}
              onChange={(e) => setNewlogo(e.target.value)}
              name="logo"
              className="mx-auto px-4 py-2 mb-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            />
          </div>

          <div className="mb-4">
            {editedBudget.map((service, index) => (
              
              <div key={index} className="mb-2">
                <div>
                  <label
                    htmlFor="lawcolor"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    REF: {service.ref} Color de: {service.title}
                  </label>
                  <input
                    type="text"
                    value={service.lawcolor}
                    onChange={(e) =>
                      handleServiceChange2(index, e.target.value)
                    }
                    name="lawcolor"
                    className=" mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-red-500"
                    style={{
                      color: getColorStyles(service.ref).textColor,
                      backgroundColor: getColorStyles(service.ref).backgroundColor,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className=" flex justify-center mb-3">
            <button
              onClick={handleUpdate}
              className="bg-clgris  hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePerso;
