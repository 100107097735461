import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useState } from "react";
import { FaDollyFlatbed, FaShoppingCart } from "react-icons/fa";
import React from "react";
import MyContext from "../../context/data/myContext";
import { deleteDoc, doc } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";

export default function Modal({
  address,
  setAddress,
  nit,
  setNit,
  email,
  setEmail,
  pay,
  setPay,
  muni,
  setMuni,
  dep,
  setDep,
  orderNow,
  allorder,
  send,
  setSend,
  eshop,
  setEshop,
  obs,
  setObs,
  datesend,
  setDatesend,
}) {
  const context = useContext(MyContext);
  const { getIdData4, getPenData } = context;
  const [idcot, setIdcot] = useState("");

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const updateBudget = async () => {
    try {
      //await deleteDoc(doc(fireDB, "budget", idcot));
      await getPenData();
    } catch (error) {}
  };

  const handleClick = async (allorder) => {
    try {
      openModal();
      await getIdData4(allorder);
      setIdcot(allorder.id);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleChange = (e) => {
    // Obtenemos la fecha del input
    const dateString = e.target.value;
  
    // Convertimos la fecha de "año-mes-día" a un objeto Date de JavaScript
    const dateObject = new Date(dateString);
  
    // Extraemos el día, mes y año del objeto Date
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // Los meses en JavaScript son base 0, por lo que sumamos 1
    const year = dateObject.getUTCFullYear();
  
    // Formateamos la fecha como "día-mes-año"
    const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  
    // Actualizamos el estado de datesend con la fecha formateada
    setDatesend(formattedDate);
  }

  return (
    <>
      <div className="  text-center text-clb">
        <button type="button" onClick={() => handleClick(allorder)}>
          <FaShoppingCart size={20} title="Enviar a Pedido" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl p-2  text-left align-middle shadow-xl transition-all bg-gray-50">
                  <section className="">
                    <div className="flex flex-col items-center justify-center py-8 mx-auto  lg:py-0">
                      <div className="w-full  rounded-lg md:mt-0 sm:max-w-md xl:p-0 ">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                          <form className="space-y-4 md:space-y-6" action="#">
                            <div>
                              <label
                                htmlFor="address"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Dirección de Entrega
                              </label>
                              <input
                                type="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                name="address"
                                id="address"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="datesend"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Fecha de Envio : {datesend}
                              </label>
                              <input
                                type="date"
                                value={datesend}
                                onChange={handleChange}
                                name="datesend"
                                id="datesend"
                                lang="es"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="nit"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                NIT
                              </label>
                              <input
                                type="text"
                                value={nit}
                                onChange={(e) => setNit(e.target.value)}
                                name="nit"
                                id="nit"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                id="email"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="pay"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Metodo de Pago
                              </label>
                              <select
                                id={pay}
                                onChange={(e) => setPay(e.target.value)}
                              >
                                <option value="">Metodo de Pago</option>
                                <option value="Efectivo">Efectivo</option>
                                <option value="Tarjeta">Tarjeta</option>
                                <option value="Transferencia">
                                  Transferencia
                                </option>
                                <option value="Deposito">Deposito</option>
                              </select>
                            </div>

                            <div>
                              <label
                                htmlFor="send"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Metodo de Envio
                              </label>
                              <select
                                id={send}
                                onChange={(e) => setSend(e.target.value)}
                              >
                                <option value="">Metodo de Envio</option>
                                <option value="Mensajeria Propia">
                                  Mensajeria Propia
                                </option>
                                <option value="CAEX">CAEX</option>
                                <option value="FORZA">FORZA</option>
                                <option value="GUATEX">GUATEX</option>
                                <option value="Entrega en Tienda">
                                  Entrega en Tienda
                                </option>
                              </select>
                            </div>

                            <div>
                              <label
                                htmlFor="eshop"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                # E-shop
                              </label>
                              <input
                                type="text"
                                value={eshop}
                                onChange={(e) => setEshop(e.target.value)}
                                name="eshop"
                                id="eshop"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="obs"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Observaciones
                              </label>
                              <input
                                type="text"
                                value={obs}
                                onChange={(e) => setObs(e.target.value)}
                                name="obs"
                                id="obs"
                                className=" border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                                required
                              />
                            </div>
                          </form>
                          <button
                            onClick={() => {
                              orderNow();
                              closeModal();
                              updateBudget();
                            }}
                            type="button"
                            className="focus:outline-none w-full text-white bg-clgris hover:bg-clgris  outline-0 font-medium rounded-lg text-sm px-5 py-2.5 "
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
