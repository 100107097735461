import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from 'axios';
import Swal from 'sweetalert2';

const Edit = () => {

    const [userData, setUserData] = useState({
        id: '', // Agrega un campo 'id' para almacenar la ID del usuario
        email: '',
        displayName: '',
        rol: '',
    });


    const navigate = useNavigate()
    const { id } = useParams()

    const handleSubmit = (event) => {
        event.preventDefault(); // Evitar que la página se recargue

        // Reemplaza la URL con la URL de tu función de Google Cloud Functions
        const apiUrl = 'https://us-central1-codileyes.cloudfunctions.net/UpdateUserData';
        const useroutemail = userData.email.split('@')[0]

        const username = `${useroutemail}@page.com`

        // Objeto que contiene la ID del usuario y los datos a actualizar
        const dataToUpdate = {
            id: userData.id, // La ID del usuario
            userData: {
                email: username, // Los datos a actualizar
                displayName: userData.displayName,
                rol: userData.rol,
            },
        };

        // Realiza una solicitud POST para actualizar los datos del usuario
        axios
            .post(apiUrl, dataToUpdate)
            .then(() => {
                navigate('/dashboard/manageUsers');
            })
            .catch((error) => {
                console.error('Error al actualizar los datos del usuario:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos del usuario. Intente nuevamente.',
                });
            });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const getDatauserbyID = async (id) => {

        const userId = id;

        const apiUrl = `https://us-central1-codileyes.cloudfunctions.net/GetUserData?userId=${userId}`;

        axios
            .get(apiUrl)
            .then((response) => {
                const userDataFromServer = response.data;
                // Actualiza el estado, incluyendo la ID del usuario
                setUserData({ ...userDataFromServer, id: userId });
            })
            .catch((error) => {
                console.error('Error al obtener los datos del usuario:', error);
            });

    }

    useEffect(() => {
        getDatauserbyID(id)
        // eslint-disable-next-line
    }, [])

    return (
        <div className='p-0 sm:p-5'>
            <div className='mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg'>
                <h1 className='text-center text-2xl mb-5'>Actualizar Datos de Usuario</h1>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col mb-3'>
                        <label className='mx-5 leading-loose'>Nombre y Apellido</label>
                        <input
                            type="text"
                            name="displayName"
                            value={userData.displayName}
                            onChange={handleInputChange}
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div className='flex flex-col mb-3'>
                        <label className=' mx-5 leading-loose'>Usuario</label>
                        <input
                            type="text"
                            name="email"
                            value={userData.email.split('@')[0]}
                            onChange={handleInputChange}
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div className='flex flex-col mb-3'>
                        <label className='mx-5 leading-loose'>Rol</label>
                        <select
                            name="rol"
                            value={userData.rol}
                            onChange={handleInputChange}
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        >
                            <option value="administrador">Administrador</option>
                            <option value="usuario">Usuario</option>
                        </select>
                    </div>

                    <div className="mt-5 flex justify-center">
                        <Link to="/dashboard/manageUsers" className='bg-clgris hover:bg-clgris text-white font-bold py-2.5 px-5 rounded m-2' style={{ height: '40px' }}>Regresar</Link>
                        <button type="submit" className='bg-clgris hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2' style={{ height: '40px' }}>Guardar Cambios</button>
                    </div>



                </form>
            </div>
        </div>
    )
}

export default Edit