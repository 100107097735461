import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const UsersManage = () => {
  //1 - configuramos los hooks
  const [usuarios, setUsuarios] = useState([]);
  //3 - Funcion para mostrar TODOS los docs
  const getUsers = () => {
    axios.get('https://us-central1-codileyes.cloudfunctions.net/UserList')
      .then((response) => {
        const sortedUsers = response.data.sort((a, b) => {
          const nameA = a.displayName.toUpperCase();
          const nameB = b.displayName.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setUsuarios(sortedUsers);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de usuarios:', error);
      });
  }
  //4 - Funcion para eliminar un usuario
  const deleteUser = async (userId) => {
    try {
      // Realiza una solicitud HTTP a tu función de Cloud Functions para eliminar usuarios
      await axios.get(`https://us-central1-codileyes.cloudfunctions.net/DeleteUser?userId=${userId}`);
      getUsers();
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      if (error.response && error.response.status === 403) {
        // Si el error es un error de permisos (código 403), muestra un mensaje específico con SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No tienes permiso para eliminar este usuario',
        });
      } else {
        // En caso de otros errores, muestra un mensaje genérico con SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al eliminar el usuario',
        });
      }
    }
  };
  //5 - Funcion de confirmacion para Sweet Alert 2
  const confirmDelete = (userId) => {
    MySwal.fire({
      title: '¿Eliminar el usuario?',
      text: "El usuario no se podra recuperar",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#027495',
      confirmButtonText: 'Si, Eliminar Usuario'
    }).then((result) => {
      if (result.isConfirmed) {
        //llamamos a la funcion para eliminar 
        deleteUser(userId)
        Swal.fire(
          'Eliminado!',
          'El usuario ha sido eliminado',
          'success'
        )
      }
    })
  }
  //6 - usamos useEffect
  useEffect(() => {
    getUsers()
    // eslint-disable-next-line
  }, [])
  //7 - devolvemos vista de nuestro componente

  
  return (
    <>
      <div className="container mx-auto p-6">
        <div className="flex d-gruid gap-2 mb-2 justify-end">
          <Link to="create" className='bg-clgris  hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5'>Crear Nuevo Usuario</Link>
        </div>
        <div className="w-full mb-8 overflow-hidden shadow-lg">

          <div className="relative overflow-x-auto shadow-md ">
            <table className='w-full text-sm text-center'>
              <thead className='text-xs border border-clgris text-white uppercase bg-clgris'>
                <tr className="text-md tracking-wide text-center text-white bg-clgris uppercase border-b border-gray-600">
                  <th scope='col' className="px-6 py-3" >Nombre y Apellido</th>
                  <th scope='col' className="px-6 py-3" >Usuario</th>
                  <th scope='col' className="px-6 py-3" >Rol</th>
                  <th scope='col' className="px-6 py-3" >Acciones</th>
                </tr >
              </thead>
              <tbody className="bg-white" >
                {usuarios.map((usuario) => (
                  <tr className="bg-gray-50 border-b  dark:border-clgris" key={usuario.uid}>
                    <td className='px-6 py-3 text-black' >{usuario.displayName}</td>
                    <td className='px-6 py-3 text-black' >{usuario.email.split('@')[0]}</td>
                    <td className='px-6 py-3 text-black' >{usuario.rol}</td>
                    <td className='px-6 py-3 text-black' >
                      <Link to={`edit/${usuario.uid}`} className="bg-clgris hover:bg-clgris text-white py-2.5 px-6  rounded mx-2">Editar</Link>
                      <button className='bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded mx-2' onClick={() => confirmDelete(usuario.uid)}>Eliminar</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersManage