import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { doc, getDoc } from "firebase/firestore";
import MyContext from "../../context/data/myContext";
import { fireDB } from "../../firebase/FirebaseConfig";
import { Link, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import "./PrintDevAsana.css";
import logoImage from "../../components/img/Logotipo-resize.png";
import html2pdf from 'html2pdf.js';

function PrintDevAsana() {
  const context = useContext(MyContext);
  const { setLoading } = context;

  const [view, setViewview] = useState("");
  const { id } = useParams();

  const getIdData2 = async () => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "asana", id));
      setViewview(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const currentYear = new Date().getFullYear();
  const currentYearTwo = currentYear.toString().slice(-2);



  /*const getCurrentYearString = () => {
    const currentYear = new Date().getFullYear();

    switch (currentYear) {
      case 2023:
        return "VT";
      case 2024:
        return "VC";
      case 2025:
        return "VCC";
      case 2026:
        return "VS";
      case 2027:
        return "VST";
      case 2028:
        return "VO";
      case 2029:
        return "VN";
      case 2030:
        return "T";
      case 2031:
        return "TU";
      case 2032:
        return "TD";
      case 2033:
        return "TT";
      case 2034:
        return "TC";
      case 205:
        return "TCC";
      case 2036:
        return "TS";
      default:
        return "Unknown";
    }
  };*/

  // const yearString = getCurrentYearString();

  const getCurrentMonthNumber = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return currentMonth;
  };

  const monthNumber = getCurrentMonthNumber();

  useEffect(() => {
    getIdData2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pdfRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const captureAndDownloadImage = () => {
    console.log("Botón de descarga presionado");

    const content = pdfRef.current;

    const logoImageObj = new Image();
    logoImageObj.src = logoImage;

    logoImageObj.onload = () => {
      console.log("Imagen del logo cargada correctamente:", logoImageObj);

      const scale = 6;

      html2canvas(content, {
        width: content.scrollWidth,
        height: content.scrollHeight,
        scale: scale,
      })
        .then((canvas) => {
          const ctx = canvas.getContext("2d");
          ctx.drawImage(logoImageObj, 10, 10, 100, 100);
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = image;
          link.download = `Pedido-${id.replace('-', '')}.png`;
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing view as image:", error);
        });
    };
  };

  const handlePdf = () => {
    const content = pdfRef.current;
  
    const options = {
      scale: 2,
      filename: `Pedido-${id.replace('-', '')}.pdf`,
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', compressPDF: true },
      html2canvas: {
        scale: 5,
        width: content.scrollWidth,
        height: content.scrollHeight,
      },
    };
  
    html2pdf().from(content).set(options).save();
  };

  // Define una función para determinar los colores basados en item.ref
  function getColorStyles(ref) {
    if (ref.includes("WOLFF")) {
      return {
        textColor: "#4EA807",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("PLUS")) {
      return {
        textColor: "#DBB600",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("DLXW")) {
      return {
        textColor: "white",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("DLX")) {
      return {
        textColor: " #DBB600",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("WOLF")) {
      return {
        textColor: "white",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("CIV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFF00",
      };
    } else if (ref.includes("MERC")) {
      return {
        textColor: "black",
        backgroundColor: "#66FF66",
      };
    } else if (ref.includes("NOT")) {
      return {
        textColor: "black",
        backgroundColor: "#FF9900",
      };
    } else if (ref.includes("ADMIN")) {
      return {
        textColor: "black",
        backgroundColor: "#00B0F0",
      };
    } else if (ref.includes("PEN")) {
      return {
        textColor: "white",
        backgroundColor: "#FF0000",
      };
    } else if (ref.includes("LABO")) {
      return {
        textColor: "black",
        backgroundColor: "#DDC88F",
      };
    } else if (ref.includes("CONV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFFC7",
      };
    } else if (ref.includes("LIB")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("PROTOCOLO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFI")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TN")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } 
    // Agrega más condiciones según tus necesidades

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  // Define a custom order for sorting
  const customOrder = [
    "WOLF",
    "PLUS",
    "DLXW",
    "DLX",
    "WOLFF",
    "CIV",
    "MERC",
    "NOT",
    "ADMIN",
    "PEN",
    "LABO",
    "CONV",
  ];
  
  const customOrderSecondStructure = [
    "CONST",
    "ORG",
    "CIV",
    "MERC",
    "NOT",
    "ADMIN",
    "PEN",
    "LABO",
  ];
  
  const extractSecondStructure = (ref) => {
    const parts = ref.split("-");
    if (parts.length >= 3) {
      return parts[1].trim(); // Cambiado de 2 a 1
    }
    return ref;
  };
  
  if (view && Array.isArray(view.cot3.budgetItems)) {
    view.cot3.budgetItems.sort((a, b) => {
      const aRef = a.ref;
      const bRef = b.ref;
      const aSecondStructure = extractSecondStructure(aRef);
      const bSecondStructure = extractSecondStructure(bRef);
  
      const aCustomOrderIndex = customOrder.indexOf(aRef.split("-")[0]);
      const bCustomOrderIndex = customOrder.indexOf(bRef.split("-")[0]);
  
      const aSecondStructureIndex = customOrderSecondStructure.indexOf(aSecondStructure);
      const bSecondStructureIndex = customOrderSecondStructure.indexOf(bSecondStructure);
  
      if (aCustomOrderIndex !== -1 && bCustomOrderIndex !== -1) {
        if (aCustomOrderIndex !== bCustomOrderIndex) {
          return aCustomOrderIndex - bCustomOrderIndex;
        }
      } else {
        if (aCustomOrderIndex === -1 && bCustomOrderIndex === -1) {
          if (aSecondStructureIndex !== bSecondStructureIndex) {
            return aSecondStructureIndex - bSecondStructureIndex;
          }
          return aRef.localeCompare(bRef);
        } else if (aCustomOrderIndex === -1) {
          return 1;
        } else {
          return -1;
        }
      }
  
      if (aSecondStructureIndex !== bSecondStructureIndex) {
        return aSecondStructureIndex - bSecondStructureIndex;
      }
  
      return aRef.localeCompare(bRef);
    });
  }

  //CALCULA EL SUBTOTAL PARA LOS ITEMS CON ORIGEN PAQUETE
  const totalForItems = view
    ? view.cot3.budgetItems
      .filter((item) => item.origen === "Paquete")
      .map((item) => {
        const total = item.quantity * item.price;
        return total;
      })
      .reduce((accumulator, itemTotal) => accumulator + itemTotal, 0)
    : 0; // Set a default value if view is not available yet

  //CALCULA EL SUBTOTRAL PARA LOS ITEMS SIN ORIGEN
  const totalForItem2 = view
    ? view.cot3.budgetItems
      .filter((item) => item.origen !== "Paquete")
      .map((item) => {
        const total = item.quantity * item.price;
        return total;
      })
      .reduce((accumulator, itemTotal) => accumulator + itemTotal, 0)
    : 0; // Set a default value if view is not available yet

  return (
    <>
      <div className="mx-auto py-8 w-auto">
        <div className="overflow-y-auto  border-4 w-fit m-auto">
          <div ref={pdfRef} className="print-content">
            <div className="mx-auto my-4 font-rubik text-center text-sm">
              <div className="py-5">
                {view && (
                  <div>
                    <div className="flex">
                      <div className="flex-shrink-0 ml-4 mb-2">
                        <img
                          className="img"
                          src={logoImage}
                          alt="CodiLeyes Logo"
                        />
                      </div>
                      <div className="flex flex-col text-white ml-4 mt-5">
                        <div className="bg-clgris grid grid-cols-2 divide-x-2 px-1 m-1 py-4 text-2xl">
                          <p className="inline px-3">
                            Pedido: 
                            
                            {id.replace('-', '')}
                            
                          </p>{" "}
                          <p className="inline px-3"> Fecha: {view.date}</p>
                        </div>
                        <div className="grid grid-cols-2 divide-x-2 px-1 bg-clgris text-white m-1">
                          <p>
                            COT: {view.cot3.cotId}-{view.cot3.iniciales}
                          </p>
                          <p>
                            Envio: <span style={{ color: 'yellow' }} className=" text-base">{view.datesend}</span>
                          </p>
                        </div>
                        <div className="grid grid-cols-3 divide-x-2 px-1 bg-clgris text-white m-1">
                          <p>www.codileyes.com</p>
                          <p>2476-4263</p>
                          <p> Tiendas Z5 y Z12</p>
                        </div>
                      </div>
                    </div>

                    <div className="inline=block bg-clgris mx-4 my-4 grid grid-cols-4 text-white text-left">
                      <span className="col-span-2 border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Nombre:</span> {view.cot3.name}
                      </span>
                      <span className="border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>NIT:</span> {view.nit}
                      </span>
                      <span className="border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Contacto:</span> {view.cot3.phoneNumber}{" "}
                      </span>
                      <span className="col-span-2 border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Correo:</span> {view.email}
                      </span>
                      <span className="col-span-1 border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Metodo de Pago:</span> {view.pay}
                      </span>
                      <span className="col-span-1 border border-gray-300 p-1">
                        <span style={{ color: 'yellow' }}># E-shop:</span> {view.eshop}
                      </span>

                      <span className="col-span-3 border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Direccion:</span> {view.address}
                      </span>
                      <span className="border border-gray-300 p-1">
                      <span style={{ color: 'yellow' }}>Envio:</span> {view.send}
                      </span>
                    </div>

                    <div className="bg-clgris py-1"></div>

                    {view.cot3.nameport !== "" && (
                      <div className="flex mx-6 font-bold border-b border-clgris">
                        <div className="text-red-600 text-left">Portada:</div>
                        <div className="text-center flex-1">
                          {" "}
                          {/* Add flex-1 class here */}
                          <span className="text-red-600 text-3xl font-extrabold">
                            {view.cot3.nameport}
                          </span>
                        </div>
                      </div>
                    )}

                    {view.cot3.nameport !== "" && (
                      <div className="flex mx-6 font-bold">
                        <div className="text-red-600 text-left">Logo:</div>
                        <div className="text-center flex-1">
                          {" "}
                          {/* Add flex-1 class here */}
                          <span className="text-red-600 text-3xl font-extrabold">
                            {view.cot3.logo}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="mx-6">
                      <table>
                        <thead>
                          <tr className="tracking-wide text-white bg-clgris uppercase text-base">
                            {view.cot3.budgetItems[0].lawcolor &&
                              view.cot3.budgetItems[0].lawcolor !== "" ? (
                              <>
                              <th className="px-1 py-2 border text-xs">No.</th>
                                <th className="px-4 py-2 border w-36 ">Ref.</th>
                                <th className="px-4 py-2 border">Producto</th>
                                <th className="px-2 py-2 border ">Color</th>
                                <th className="px-2 py-2 border text-xs">
                                  Cant.
                                </th>
                                <th className="px-6 py-2 border">Precio</th>
                              </>
                            ) : (
                              <>
                              <th className="px-1 py-2 border text-xs">No.</th>
                                <th className="px-4 py-2 border w-36">Ref.</th>
                                <th className="px-4 py-2 border ">Producto</th>
                                <th className="px-2 py-2 border text-xs">
                                  Cant.
                                </th>
                                <th className="px-6 py-2 border">Precio</th>
                              </>
                            )}
                          </tr>
                        </thead>

                        {view &&
                          view.cot3.budgetItems.filter(
                            (item) => item.origen === "Paquete"
                          ).length > 0 && (
                            <tr className=" text-right text-base border-b"
                            style={{
                              backgroundColor: view.cot3.bcolor || "bg-clgris", // Establece bg-clgris si view.bcolor es vacío
                              color: view.cot3.tcolor || "text-white", // Establece text-white si view.tcolor es vacío
                            }}
                            >
                              {view.cot3.budgetItems[0].lawcolor &&
                                view.cot3.budgetItems[0].lawcolor !== "" ? (
                                <>
                                  <td></td>
                                  <td></td>
                                  <td className=" text-center">
                                    {" "}
                                    {view.cot3.namepack}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td> </td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  
                                  <td className=" text-center">
                                    {" "}
                                    {view.cot3.namepack}
                                  </td>
                                  <td></td>
                                  <td> </td>
                                  <td> </td>
                                </>
                              )}
                            </tr>
                          )}

                        <tbody>
                          {view.cot3.budgetItems
                            .filter((item) => item.origen === "Paquete")
                            .map((item, index) => {
                              const { textColor, backgroundColor } =
                                getColorStyles(item.ref);
                                const renderedIndex = index + 1
                              return (
                                <tr>
                                  {item.lawcolor && item.lawcolor !== "" ? (
                                    <>
                                    <td
                                    className="border"
                                    style={{ width: "2%" }}
                                  >
                                    {renderedIndex} 
                                  </td>
                                      <td
                                        className="px-2 border text-left w-36 font-bold"
                                        style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {item.ref}
                                      </td>
                                      <td className="px-2 border text-left">
                                        {item.title}
                                      </td>
                                      <td className="px-2 border text-red-700 font-extrabold" style={{ width: "10%" }}>
                                        {item.lawcolor}
                                      </td>
                                      <td
                                        className="px-2 border"
                                        style={{ width: "5%" }}
                                      >
                                        {item.quantity}
                                      </td>
                                      <td
                                        className="px-2 border  text-xl bg-slate-600 text-white"
                                        style={{ width: "15%" }}
                                      >
                                        Q. {item.price}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                    <td
                                    className="border"
                                    style={{ width: "2%" }}
                                  >
                                    {renderedIndex} 
                                  </td>
                                      <td
                                        className="px-2 border text-left w-36 font-bold"
                                        style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {item.ref}
                                      </td>
                                      <td className="px-2 border text-left">
                                        {item.title}
                                      </td>
                                      <td
                                        className="px-2 border"
                                        style={{ width: "5%" }}
                                      >
                                        {item.quantity}
                                      </td>
                                      <td
                                        className="px-2 border text-xl bg-slate-600 text-white"
                                        style={{ width: "15%" }}
                                      >
                                        Q. {item.price}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}

                          {view &&
                            view.cot3.budgetItems.filter(
                              (item) => item.origen === "Paquete"
                            ).length > 0 && (
                              <tr className="bg-clgris py-4 text-white text-right text-base border-b">
                                {view.cot3.budgetItems[0].lawcolor &&
                                  view.cot3.budgetItems[0].lawcolor !== "" ? (
                                  <>
                                  <td></td>
                                    <td colSpan="3" className="pr-1 border-r">Subtotal:</td>
                                    <td colSpan="2" className="px-2 border text-xl bg-clgris text-white text-center">
                                      Q. {totalForItems}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td colSpan="2" className="pr-1 border-r">Subtotal:</td>
                                    <td colSpan="2" className="px-2 border text-xl bg-clgris text-white text-center">
                                      Q. {totalForItems}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}

                          {view.cot3.budgetItems
                            .filter((item) => item.origen !== "Paquete")
                            .map((item, index) => {
                              const { textColor, backgroundColor } =
                                getColorStyles(item.ref);
                                const renderedIndex = index + 1
                              return (
                                <tr>
                                  {item.lawcolor && item.lawcolor !== "" ? (
                                    <>
                                    <td
                                    className="border"
                                    style={{ width: "2%" }}
                                  >
                                    {renderedIndex} 
                                  </td>
                                      <td
                                        className="px-2 border text-left w-36 font-bold"
                                        style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {item.ref}
                                      </td>
                                      <td className="px-2 border text-left">
                                        {item.title}
                                      </td>
                                      <td className="px-2 border text-red-700 font-extrabold " style={{ width: "15%" }}>
                                        {item.lawcolor}
                                      </td>
                                      <td
                                        className="px-2 border"
                                        style={{ width: "5%" }}
                                      >
                                        {item.quantity}
                                      </td>
                                      <td
                                        className="px-2 border  text-xl bg-slate-600 text-white"
                                        style={{ width: "15%" }}
                                      >
                                        Q. {item.price}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                    <td
                                    className="border"
                                    style={{ width: "2%" }}
                                  >
                                    {renderedIndex} 
                                  </td>
                                      <td
                                        className="px-2 border text-left w-36 font-bold"
                                        style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {item.ref}
                                      </td>
                                      <td className="px-2 border text-left">
                                        {item.title}
                                      </td>
                                      <td
                                        className="px-2 border"
                                        style={{ width: "5%" }}
                                      >
                                        {item.quantity}
                                      </td>
                                      <td
                                        className="px-2 border text-xl bg-slate-600 text-white"
                                        style={{ width: "15%" }}
                                      >
                                        Q. {item.price}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}

                          {view &&
                            view.cot3.budgetItems.filter(
                              (item) => item.origen !== "Paquete"
                            ).length > 0 && (
                              <tr className="bg-clgris py-4 text-white text-right text-base border-b">
                                {view.cot3.budgetItems[0].lawcolor &&
                                  view.cot3.budgetItems[0].lawcolor !== "" ? (
                                  <>
                                  <td></td>
                                    <td colSpan="3" className="pr-1 border-r">Subtotal:</td>
                                    <td colSpan="2" className="px-2 border text-xl bg-clgris text-white text-center">
                                      Q. {totalForItem2}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                  <td></td>
                                    <td colSpan="2" className="pr-1 border-r">Subtotal:</td>
                                    <td colSpan="2" className="px-2 border text-xl bg-clgris text-white text-center">
                                      Q. {totalForItem2}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}

                          {view.cot3.budgetItems[0].lawcolor &&
                            view.cot3.budgetItems[0].lawcolor !== "" ? (
                            <>
                              <tr className="bg-clgris text-white text-base border-b ">
                              <td></td>
                                <td colSpan="3" className="text-right border-r pr-1">
                                  Envio:
                                </td>
                                <td colSpan="2"  className="text-xl">Q. {view.cot3.newshipping}</td>
                              </tr>

                              <tr className="bg-clgris text-white text-base border-b">
                                
                                <td colSpan="4" className="text-right w-40 border-r pr-1">
                                  Descuento:
                                </td>
                                <td colSpan="2" className=" text-yellow-400 text-xl">
                                  -Q. {view.cot3.discountPercentage}
                                </td>
                              </tr>

                              <tr className="bg-clgris text-white text-base ">
                              <td></td>
                                <td colSpan="3" className="text-right border-r px-2 divide-x-0">
                                  TOTAL:{" "}
                                </td>
                                <td colSpan="2" className=" text-xl">
                                  Q. {view.cot3.grandTotal}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr className="bg-clgris text-white text-base border-b ">
                                <td colSpan="3" className="text-right border-r pr-1">
                                  Envio:
                                </td>
                                <td colSpan="2" className="text-xl">Q. {view.cot3.newshipping}</td>
                              </tr>

                              <tr className="bg-clgris text-white text-base border-b">
                                
                                <td colSpan="3" className="text-right w-40 border-r pr-1">
                                  Descuento:
                                </td>
                                <td colSpan="2" className=" text-yellow-400 text-xl">
                                  -Q. {view.cot3.discountPercentage}
                                </td>
                              </tr>

                              <tr className="bg-clgris text-white text-base ">
                                <td colSpan="3" className="text-right px-2 divide-x-0">
                                  TOTAL:{" "}
                                </td>
                                <td colSpan="2" className=" text-xl">
                                  Q. {view.cot3.grandTotal}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {view.obs !== "" && (
                      <div className="mx-auto text-red-600 bg-yellow-500 text-left px-6 py-4 mt-5 w-11/12 font-extrabold text-xl">
                        <p>OBSERVACIÓN: {view.obs}</p>
                      </div>
                    )}


                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className=" mx-auto text-white font-rubik rounded-lg text-center flex">
          <button
            onClick={captureAndDownloadImage}
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
          >
            Descargar Imagen
          </button>
          <button
            onClick={handlePdf}
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
          >
            Descargar PDF
          </button>
          <button
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
            onClick={handlePrint}
          >
            Imprimir
          </button>
          <Link className="bg-clgris hover:bg-clgris rounded-lg p-2" to="../asana">
            Regresar
          </Link>
        </div>
      </div>
    </>
  );
}

export default PrintDevAsana;