import React from 'react';

function Footer() {
    return (
        <footer className="bg-clgris p-4 text-center absolute bottom-0 w-full text-white">
            <p>&copy; {new Date().getFullYear()} Dragon Devs</p>
        </footer>
    );
}

export default Footer;