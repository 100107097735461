
import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../../context/data/myContext";
import { FaPrint, FaRegEdit, FaPen, FaBroom, FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalDeliveryPen from "../../../components/modal/ModalDeliveryPen";
import { toast } from "react-toastify";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireDB } from "../../../firebase/FirebaseConfig";
import ModalDelete from "../../../components/modal/ModalDelete";
import SearchBar5 from "../../../components/searchbar/SearchBar5";
import ModalRestPen from "../../../components/modal/ModalRestPen";
import ModalBudArchive2 from "../../../components/modal/ModalBudArchive2";
import ModalSendArch2 from "../../../components/modal/ModalSendArch2";

function BudPendiente() {

    const context = useContext(MyContext);
  const {
    deleteBudPen,
    pen,
    cot4,
    getOrderData,
    getIdData4,
    getPenData,
    edithandle8,
    filteredData5,
    getIdResPendiente,
    bud2,
    getDeliveryData
  } = context;
  const budgetItems = useSelector((state) => state.budget);

  useEffect(() => {
    getPenData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIdData4(pen);
    // eslint-disable-next-line
  }, []);

  const [address, setAddress] = useState("");
  const [nit, setNit] = useState("");
  const [email, setEmail] = useState("");
  const [pay, setPay] = useState("");
  const [muni, setMuni] = useState("");
  const [dep, setDep] = useState("");
  const [send, setSend] = useState("");
  const [eshop, setEshop] = useState("");
  const [obs, setObs] = useState("");
  const [datesend, setDatesend] = useState("");
  const [notes] = useState("");
  const [process, setProcesss] = useState("EN PROCESO");
  const [factura, setFactura] = useState(0);
  //const [lastAssignedId, setLastAssignedId] = useState(0);
  const [lastAssignedIdForYearMonth, setLastAssignedIdForYearMonth] =
    useState(0);
  const [lastAssignedIdYearMonthKey, setLastAssignedIdYearMonthKey] =
    useState("");
    

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLastAssignedId = async () => {
      const docRef = doc(fireDB, "metaData", "lastAssignedIdDelivery");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLastAssignedIdForYearMonth(docSnap.data()[yearMonthKey] || 0);
        setLastAssignedIdYearMonthKey(docSnap.data().lastIdYearMonthKey || "");
      }
    };
    fetchLastAssignedId();
    // eslint-disable-next-line
  }, []);

  const generateFormattedId = () => {
    const yearString = getCurrentYearString();
    //const currentYear = new Date().getFullYear();
    const currentMonth = getCurrentMonthNumber();

    const newId = (lastAssignedIdForYearMonth + 1).toString().padStart(3, "0");
    const yearMonthKey = `${yearString}${currentMonth
      .toString()
      .padStart(2, "0")}`;

    if (yearMonthKey !== lastAssignedIdYearMonthKey) {
      setLastAssignedIdForYearMonth(0);
      setLastAssignedIdYearMonthKey(yearMonthKey);
    }

    return `${yearMonthKey}-${newId}`;
  };

  const getCurrentYearString = () => {
    const currentYear = new Date().getFullYear();

    switch (currentYear) {
      case 2023:
        return "23";
      case 2024:
        return "24";
      case 2025:
        return "25";
      case 2026:
        return "26";
      case 2027:
        return "27";
      case 2028:
        return "28";
      case 2029:
        return "29";
      case 2030:
        return "30";
      default:
        return "Unknown";
    }
  };

  const getCurrentMonthNumber = () => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1;
  };

  const yearString = getCurrentYearString();
  const monthNumber = getCurrentMonthNumber();
  const yearMonthKey = `${yearString}${monthNumber
    .toString()
    .padStart(2, "0")}`;

  const orderNow = async () => {
    const devInfo = {
      address,
      nit,
      email,
      pay,
      muni,
      dep,
      send,
      eshop,
      cot3:{ ...cot4},
      process,
      obs,
      factura,
      datesend,
      notes,
      yearString: getCurrentYearString(),
      monthNumber: getCurrentMonthNumber(),
      date: new Date().toLocaleString("es-ES", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };

    const newId = generateFormattedId();
    const counterRef = doc(fireDB, "metaData", "lastAssignedIdDelivery");

    try {
      const docSnap = await getDoc(counterRef);
      const lastIdForYearMonth = docSnap.exists()
        ? docSnap.data()[yearMonthKey] || 0
        : 0;
      const newLastIdForYearMonth = lastIdForYearMonth + 1;
      const newLastId = `${yearMonthKey}-${newLastIdForYearMonth
        .toString()
        .padStart(3, "0")}`;

      await setDoc(doc(fireDB, "delivery", newLastId), {
        [yearMonthKey]: newLastIdForYearMonth,
        lastId: newLastId,
        lastIdYearMonthKey: yearMonthKey,
        ...devInfo,
      });

      toast.success("Pedido Guardado", {
        position: toast.POSITION.TOP_LEFT,
      });

      getDeliveryData();

      setAddress("");
      setNit("");
      setEmail("");
      setPay("");
      setMuni("");
      setDep("");
      setSend("");
      setEshop("");
      setProcesss("");
      setObs("");
      setFactura("");
      setDatesend("");

      navigate("../delivery");

      await setDoc(
        counterRef,
        { [yearMonthKey]: newLastIdForYearMonth, lastId: newLastId },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating counter:", error);
    }
  };

  const handleDeleteConfirmation = (allorder) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
        deleteBudPen(allorder);
        getPenData()
    }
  };

  const sortedOrders = pen.slice().sort((a, b) => {
    const cotIdA = parseInt(a.cotId, 10);
    const cotIdB = parseInt(b.cotId, 10);
    return cotIdB - cotIdA;
  });


  const Archive = async (allorder) => {
    // Llamada a la función para obtener el valor de dev3
    await getIdResPendiente(allorder);

    if (bud2) {
      // Verifica que dev3 no sea vacío
      const archiInfo = {
        ...bud2
      };

      try {
        //await addDoc(collection(fireDB, "budPendiente"), { ...archiInfo });
        await setDoc(doc(fireDB, "budget", allorder.id), { ...archiInfo });
        toast.success("Restaurado", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteBudPen(allorder);
        await getOrderData();

        navigate("../budgets");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede archivar.");
    }
  };

  const Archive2 = async (allorder) => {
    let retries = 0;
        while (!bud2 && retries < 5) { // Add a limit to retries to prevent infinite loop
          await getIdResPendiente(allorder);
          retries += 1;
          // You may want to add a delay here to avoid continuous checking
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
        }

        if (!bud2) {
          console.log("bud2 sigue vacío después de intentos múltiples, no se puede archivar.");
          return; // Terminar la función si bud1 sigue vacío después de los intentos
        }
    
        const archiInfo = {
          ...bud2
        };

      try {
        //await addDoc(collection(fireDB, "budPendiente"), { ...archiInfo });
        await setDoc(doc(fireDB, "budArchive", allorder.id), { ...archiInfo });
        toast.success("Enviado a Archivo", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteBudPen(allorder);
        
        //setBud2("");

        navigate("../budArchive");
      } catch (error) {
        console.log(error);
      }
  };

  


  return (
    <div className="relative overflow-x-auto max-w-screen-xl mx-auto my-5">
        <div>
        <h1 className=" text-center text-2xl">Cotizaciones Confirmadas</h1>
      </div>
      <div className="flex mb-2">
        <div className="flex px-6 justify-start mx-0.5 border border-gray-400 py-2 rounded">
          <SearchBar5 />
        </div>
        <div className="flex-grow"></div>

        <Link to={"../budgetbook"}>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            Cotizar
          </button>
        </Link>
        <Link
          to={"../budget"}
          className="bg-clgris hover-bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
        >
          Validar
          <span className="ml-2 text-sm font-medium text-white group-">
            {budgetItems.length}
          </span>
        </Link>
        <div>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            <ModalSendArch2 />
          </button>
        </div>

        <Link to={"../budgets"}>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            Regresar
          </button>
        </Link>
      </div>

      

      {filteredData5.length === 0 ? (
        <div className="realtive overflow-x-auto shadow-md">
          <table className="w-full text-sm text-center mb-8">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No. de cotizacion
                </th>
                <th scope="col" className="px-6 py-3">
                  Fecha
                </th>
                <th scope="col" className="px-6 py-3">
                  No. de Tel.
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3">
                  Precio Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Acciones
                </th>
              </tr>
            </thead>

            {sortedOrders.map((allorder) => (
              <tbody key={allorder.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {allorder.id}-{allorder.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {allorder.date}
                  </td>
                  <td className="px-2 py-3 text-black" style={{ width: "10%" }}>{allorder.phoneNumber}</td>
                  <td className="px-6 py-3 text-black">{allorder.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {allorder.grandTotal}
                  </td>
                  <td className="px-6 py-3">
                    <div className=" inline-flex gap-2 cursor-pointer text-black justify-center ">
                    <div>
                            <ModalBudArchive2 Archive={Archive2} allorder={allorder} />
                          </div>
                      <Link to={"../updatenamepack2"}>
                        <FaBroom
                          onClick={() => edithandle8(allorder)}
                          size={20}
                          title="Asignar nombre de paquete"
                        />
                      </Link>
                      <div>
                        <Link to={`../printbudpen/${allorder.id}`}>
                          <FaPrint size={20} title="Imprimir" />
                        </Link>
                      </div>
                      <Link to={`../updatebudpen/${allorder.id}`}>
                        <FaEdit size={20} title="Editar" />
                      </Link>
                      <div>
                        <ModalDeliveryPen
                          address={address}
                          setAddress={setAddress}
                          nit={nit}
                          setNit={setNit}
                          email={email}
                          setEmail={setEmail}
                          pay={pay}
                          setPay={setPay}
                          muni={muni}
                          setMuni={setMuni}
                          dep={dep}
                          setDep={setDep}
                          orderNow={orderNow}
                          send={send}
                          setSend={setSend}
                          eshop={eshop}
                          setEshop={setEshop}
                          obs={obs}
                          setObs={setObs}
                          datesend={datesend}
                          setDatesend={setDatesend}
                          allorder={allorder}
                        />
                      </div>

                      <div>
                            <ModalRestPen Archive={Archive} dev={allorder} />
                          </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <div className="realtive overflow-x-auto shadow-md">
          <table className="w-full text-sm text-center mb-8">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No. de cotizacion
                </th>
                <th scope="col" className="px-6 py-3">
                  Fecha
                </th>
                <th scope="col" className="px-6 py-3">
                No. de Tel.
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3">
                  Precio Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Acciones
                </th>
              </tr>
            </thead>

            {filteredData5.map((allorder) => (
              <tbody key={allorder.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {allorder.id}-{allorder.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {allorder.date}
                  </td>
                  <td className="px-6 py-3 text-black" style={{ width: "10%" }}>{allorder.phoneNumber}</td>
                  <td className="px-6 py-3 text-black">{allorder.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {allorder.grandTotal}
                  </td>
                  <td className="px-6 py-3">
                    <div className=" inline-flex gap-2 cursor-pointer text-black justify-center ">
                    <div>
                            <ModalBudArchive2 Archive={Archive2} allorder={allorder} />
                          </div>
                      <Link to={"../updatenamepack2"}>
                        <FaBroom
                          onClick={() => edithandle8(allorder)}
                          size={20}
                          title="Asignar nombre de paquete"
                        />
                      </Link>
                      <div>
                        <Link to={`../printbudpen/${allorder.id}`}>
                          <FaPrint size={20} title="Imprimir" />
                        </Link>
                      </div>
                      <Link to={`../updatebudpen/${allorder.id}`}>
                        <FaEdit size={20} title="Editar" />
                      </Link>
                      <div>
                        <ModalDeliveryPen
                          address={address}
                          setAddress={setAddress}
                          nit={nit}
                          setNit={setNit}
                          email={email}
                          setEmail={setEmail}
                          pay={pay}
                          setPay={setPay}
                          muni={muni}
                          setMuni={setMuni}
                          dep={dep}
                          setDep={setDep}
                          orderNow={orderNow}
                          send={send}
                          setSend={setSend}
                          eshop={eshop}
                          setEshop={setEshop}
                          obs={obs}
                          setObs={setObs}
                          datesend={datesend}
                          setDatesend={setDatesend}
                          allorder={allorder}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
    </div>
  )
}

export default BudPendiente