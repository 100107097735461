import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { doc, getDoc } from "firebase/firestore";
import MyContext from "../../context/data/myContext";
import { fireDB } from "../../firebase/FirebaseConfig";
import { Link, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import "./PrintCoti.css";
import logoImage from "../../components/img/dd1.png";
import banerImage from "../../components/img/Banner Cotización CodiLeyes.png";
import html2pdf from "html2pdf.js";

function PrintCoti() {
  const context = useContext(MyContext);
  const { setLoading } = context;

  const [view, setView] = useState("");
  const { id } = useParams();

  const getIdData2 = async () => {
    setLoading(true);
    try {
      const budgetID = await getDoc(doc(fireDB, "budget", id));
      setView(budgetID.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getIdData2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pdfRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const captureAndDownloadImage = () => {
    const content = pdfRef.current;

    const logoImageObj = new Image();
    logoImageObj.src = logoImage;

    logoImageObj.onload = () => {
      console.log("Imagen del logo cargada correctamente:", logoImageObj);

      const scale = 6;

      html2canvas(content, {
        width: content.scrollWidth,
        height: content.scrollHeight,
        scale: scale,
      })
        .then((canvas) => {
          const ctx = canvas.getContext("2d");
          ctx.drawImage(logoImageObj, 10, 10, 100, 100);
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = image;
          link.download = `Cotizacion-${id}.png`;
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing view as image:", error);
        });
    };
  };

  const handlePdf = () => {
    const content = pdfRef.current;

    const options = {
      scale: 2,
      filename: `Cotizacion-${id}-${view.iniciales}.pdf`,
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
        compressPDF: true,
      },
      html2canvas: {
        scale: 5,
        width: content.scrollWidth,
        height: content.scrollHeight,
      },
    };

    html2pdf().from(content).set(options).save();
  };

  // Define una función para determinar los colores basados en item.ref
  function getColorStyles(ref) {
    if (ref.includes("WOLFF")) {
      return {
        textColor: "#4EA807",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("PLUS")) {
      return {
        textColor: "#DBB600",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("DLXW")) {
      return {
        textColor: "white",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("DLX")) {
      return {
        textColor: " #DBB600",
        backgroundColor: "#05274C",
      };
    } else if (ref.includes("WOLF")) {
      return {
        textColor: "white",
        backgroundColor: "#09667C",
      };
    } else if (ref.includes("CIV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFF00",
      };
    } else if (ref.includes("MERC")) {
      return {
        textColor: "black",
        backgroundColor: "#66FF66",
      };
    } else if (ref.includes("NOT")) {
      return {
        textColor: "black",
        backgroundColor: "#FF9900",
      };
    } else if (ref.includes("ADMIN")) {
      return {
        textColor: "black",
        backgroundColor: "#00B0F0",
      };
    } else if (ref.includes("PEN")) {
      return {
        textColor: "white",
        backgroundColor: "#FF0000",
      };
    } else if (ref.includes("LABO")) {
      return {
        textColor: "black",
        backgroundColor: "#DDC88F",
      };
    } else if (ref.includes("CONV")) {
      return {
        textColor: "black",
        backgroundColor: "#FFFFC7",
      };
    } else if (ref.includes("LIB")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("PROTOCOLO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFI")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TFO")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    } else if (ref.includes("TN")) {
      return {
        textColor: "black",
        backgroundColor: "#FFB5DE",
      };
    }
    // Agrega más condiciones según tus necesidades

    // Valor predeterminado
    return {
      textColor: "black",
      backgroundColor: "white",
    };
  }

  // Define a custom order for sorting
  const customOrder = [
    "WOLF",
    "PLUS",
    "DLXW",
    "DLX",
    "WOLFF",
    "CIV",
    "MERC",
    "NOT",
    "ADMIN",
    "PEN",
    "LABO",
    "CONV",
  ];

  // Sort the budgetItems array based on the custom order and numeric part
  if (view && Array.isArray(view.budgetItems)) {
    view.budgetItems.sort((a, b) => {
      // Function to extract the second structure from item.ref
      const extractSecondStructure = (ref) => {
        const parts = ref.split("-");
        if (parts.length >= 2) {
          return parts[1].trim();
        }
        return ref;
      };

      // Define a custom order for the second structure
      const customOrderSecondStructure = [
        "CONST",
        "ORG",
        "CIV",
        "MERC",
        "NOT",
        "ADMIN",
        "PEN",
        "LABO",
      ];

      const aRef = a.ref;
      const bRef = b.ref;
      const aSecondStructure = extractSecondStructure(aRef);
      const bSecondStructure = extractSecondStructure(bRef);

      // Compare items based on the custom order of the first structure
      const aCustomOrderIndex = customOrder.indexOf(aRef.split("-")[0]);
      const bCustomOrderIndex = customOrder.indexOf(bRef.split("-")[0]);

      // Get the index in the custom order for the second structure
      const aSecondStructureIndex =
        customOrderSecondStructure.indexOf(aSecondStructure);
      const bSecondStructureIndex =
        customOrderSecondStructure.indexOf(bSecondStructure);

      // Compare items based on their custom order index and then by the second structure
      if (aCustomOrderIndex !== -1 && bCustomOrderIndex !== -1) {
        if (aCustomOrderIndex !== bCustomOrderIndex) {
          return aCustomOrderIndex - bCustomOrderIndex;
        }
      } else {
        // If one or both items don't have a custom order index, move them to the end
        if (aCustomOrderIndex === -1 && bCustomOrderIndex === -1) {
          // If both don't have custom order indices, compare by second structure
          if (aSecondStructureIndex !== bSecondStructureIndex) {
            return aSecondStructureIndex - bSecondStructureIndex;
          }
          // If both don't have custom order indices and second structure indices are equal, compare by original order
          return aRef.localeCompare(bRef);
        } else if (aCustomOrderIndex === -1) {
          return 1; // Move item without custom order to the end
        } else {
          return -1; // Move item without custom order to the end
        }
      }

      if (aSecondStructureIndex !== bSecondStructureIndex) {
        return aSecondStructureIndex - bSecondStructureIndex;
      }

      // If both items have the same custom order index and second structure index, compare them based on their original order
      return aRef.localeCompare(bRef);
    });
  }

  //CALCULA EL SUBTOTAL PARA LOS ITEMS CON ORIGEN PAQUETE
  const totalForItems = view
    ? view.budgetItems
        .filter((item) => item.origen === "Paquete")
        .map((item) => {
          const total = item.quantity * item.price;
          return total;
        })
        .reduce((accumulator, itemTotal) => accumulator + itemTotal, 0)
    : 0; // Set a default value if view is not available yet

  //CALCULA EL SUBTOTRAL PARA LOS ITEMS SIN ORIGEN
  const totalForItem2 = view
    ? view.budgetItems
        .filter((item) => item.origen !== "Paquete")
        .map((item) => {
          const total = item.quantity * item.price;
          return total;
        })
        .reduce((accumulator, itemTotal) => accumulator + itemTotal, 0)
    : 0; // Set a default value if view is not available yet

  return (
    <>
      <div className="mx-auto py-8 w-auto">
        <div className="overflow-y-auto  border-4 w-fit m-auto">
          <div ref={pdfRef} className="print-content">
            <div className="mx-auto my-4 font-rubik text-center text-sm">
              <div className="py-5">
                {view && (
                  <div>
                    <div className="flex">
                      <div className="flex-shrink-0 ml-4 mb-2">
                        <img
                          className="img"
                          src={logoImage} // Usa la variable con la ruta de la imagen
                          alt="CodiLeyes Logo"
                        />
                      </div>
                      <div className="flex flex-col text-white ml-4 mt-5">
                        <div className="bg-clgris grid grid-cols-2 divide-x-2 px-1 m-1 py-4 text-2xl">
                          <p className="inline px-3">
                            Cotizacion: {id}-{view.iniciales}
                          </p>{" "}
                          <p className="inline px-3"> Fecha: {view.date}</p>
                        </div>
                        <div className="grid grid-cols-3 divide-x-2 px-1 bg-clgris text-white m-1">
                          <p>4624-7810</p>
                          <p>5315-8107</p>
                          <p>4192-3202</p>
                        </div>
                      </div>
                    </div>

                    <div className="bg-clgris py-1"></div>
                    <div className="grid grid-cols-3 mx-4 text-2xl py-4">
                      <span>
                        Para: <span className="text-red-600">{view.name}</span>
                      </span>
                      <span>
                        Teléfono:{" "}
                        <span className="text-red-600">{view.phoneNumber}</span>
                      </span>
                      
                    </div>
                    <div className="mx-6">
                      <table className="w-full">
                        <thead>
                          <tr className="tracking-wide text-white bg-clgris uppercase text-base">
                            <th className="px-1 py-2 border text-xs">No.</th>
                            <th className="px-4 py-2 border w-28">Ref.</th>
                            <th className="px-4 py-2 border">Producto</th>
                            <th className="px-2 py-2 border  text-xs">Cant.</th>
                            <th className="px-6 py-2 border">Precio</th>
                          </tr>
                        </thead>

                        {view &&
                          view.budgetItems.filter(
                            (item) => item.origen === "Paquete"
                          ).length > 0 && (
                            <tr
                              className="py-4 text-right text-base border-b"
                              style={{
                                backgroundColor: view.bcolor || "bg-clgris", // Establece bg-clgris si view.bcolor es vacío
                                color: view.tcolor || "text-white", // Establece text-white si view.tcolor es vacío
                              }}
                            >
                              <td className="py-3"></td>
                              <td></td>
                              <td className="text-center">{view.namepack}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}

                        <tbody>
                          {view &&
                            view.budgetItems
                              .filter((item) => item.origen === "Paquete") // Filter out items where origen is "Paquete"
                              .map((item, index) => {
                                const { textColor, backgroundColor } =
                                  getColorStyles(item.ref);

                                const renderedIndex = index + 1;

                                return (
                                  <tr className="text-xs" key={item.ref}>
                                    <td
                                      className="border"
                                      style={{ width: "2%" }}
                                    >
                                      {renderedIndex}
                                    </td>
                                    <td
                                      className="px-2 border text-left w-36 font-bold"
                                      style={{
                                        color: textColor,
                                        backgroundColor: backgroundColor,
                                      }}
                                    >
                                      {item.ref}
                                    </td>
                                    <td className="px-2 border text-left">
                                      {item.title}
                                    </td>
                                    <td
                                      className="px-2 border"
                                      style={{ width: "5%" }}
                                    >
                                      {item.quantity}
                                    </td>
                                    <td className="px-2 border text-xl bg-slate-600 text-white">
                                      Q. {item.price}
                                    </td>
                                  </tr>
                                );
                              })}

                          {view &&
                            view.budgetItems.filter(
                              (item) => item.origen === "Paquete"
                            ).length > 0 && (
                              <tr className="bg-clgris py-4 text-white text-right text-base border-b">
                                <td></td>
                                <td colSpan="2" className="pr-1 border-r">
                                  {" "}
                                  Subtotal:
                                </td>
                                <td colSpan="2" className="px-2  border text-xl bg-clgris text-white text-center">
                                  Q. {totalForItems}
                                </td>
                              </tr>
                            )}

                          {view &&
                            view.budgetItems
                              .filter((item) => item.origen !== "Paquete") // Filter out items where origen is not "Paquete"
                              .map((item, index) => {
                                const { textColor, backgroundColor } =
                                  getColorStyles(item.ref);

                                const renderedIndex = index + 1;

                                return (
                                  <tr className="text-xs" key={item.ref}>
                                    <td
                                      className="border"
                                      style={{ width: "2%" }}
                                    >
                                      {renderedIndex}
                                    </td>
                                    <td
                                      className="px-2 border text-left w-36 font-bold"
                                      style={{
                                        color: textColor,
                                        backgroundColor: backgroundColor,
                                      }}
                                    >
                                      {item.ref}
                                    </td>
                                    <td className="px-2 border text-left">
                                      {item.title}
                                    </td>
                                    <td
                                      className="px-2 border"
                                      style={{ width: "5%" }}
                                    >
                                      {item.quantity}
                                    </td>
                                    <td className="px-2 border text-xl bg-slate-600 text-white">
                                      Q. {item.price}
                                    </td>
                                  </tr>
                                );
                              })}

                          {view &&
                            view.budgetItems.filter(
                              (item) => item.origen !== "Paquete"
                            ).length > 0 && (
                              <tr className="bg-clgris py-4 text-white text-right text-base border-b">
                                <td></td>
                                <td colSpan="2" className="pr-1 border-r">
                                  Subtotal:
                                </td>
                                <td colSpan="2" className="px-2 border text-xl bg-clgris text-white text-center">
                                  Q. {totalForItem2}
                                </td>
                              </tr>
                            )}

                          <tr className="bg-clgris text-white text-base border-b ">
                            <td></td>
                            <td
                              colSpan="2"
                              className="text-right border-r pr-1"
                            >
                              Envio:
                            </td>
                            <td colSpan="2" className="px-2 border text-xl bg-slate-600 text-white">
                              Q. {view.newshipping}
                            </td>
                          </tr>

                          <tr className="bg-clgris text-white text-base border-b">
                            <td></td>
                            <td
                              colSpan="2"
                              className="text-right w-40 border-r pr-1"
                            >
                              Descuento:
                            </td>
                            <td colSpan='2' className="px-2 border text-xl bg-slate-600  text-yellow-500 text-center">
                              -Q. {view.discountPercentage}
                            </td>
                          </tr>

                          <tr className="bg-clgris text-white text-base border-b">
                            <td></td>
                            <td
                              colSpan="2"
                              className="text-right divide-x-0 border-r pr-1"
                            >
                              TOTAL:{" "}
                            </td>
                            <td colSpan="2" className="px-2 border text-xl  bg-clgris text-white text-center">
                              Q. {view.grandTotal}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    
                    
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className=" mx-auto text-white font-rubik rounded-lg text-center flex">
          <button
            onClick={captureAndDownloadImage}
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
          >
            Descargar Imagen
          </button>
          <button
            onClick={handlePdf}
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
          >
            Descargar PDF
          </button>
          <button
            className="mr-2 bg-clgris hover:bg-clgris rounded-lg p-2"
            onClick={handlePrint}
          >
            Imprimir
          </button>
          <Link className="bg-clgris hover:bg-clgris rounded-lg p-2" to="../budgets">
            Regresar
          </Link>
        </div>
      </div>
    </>
  );
}

export default PrintCoti;
