

import React, { useContext, useEffect } from "react";
import MyContext from "../../../context/data/myContext";
import { FaPrint, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { fireDB } from "../../../firebase/FirebaseConfig";
import ModalDelete from "../../../components/modal/ModalDelete";
import SearchBar6 from "../../../components/searchbar/SearchBar6";
import ModalRestArch from "../../../components/modal/ModalRestArch";

function BudArchive() {
    const context = useContext(MyContext);
  const {
    deleteBudArchive,
    budArch,
    getDeliveryData,
    getIdData4,
    getBudArchData,
    filteredData6,
    getIdResArchive,
    bud4
  } = context;
  const budgetItems = useSelector((state) => state.budget);

  useEffect(() => {
    getBudArchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIdData4(budArch);
    // eslint-disable-next-line
  }, []);

    

  const navigate = useNavigate();

  

 

  const handleDeleteConfirmation = (allorder) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
        deleteBudArchive(allorder);
        getBudArchData()

    }
  };

  const sortedOrders = budArch.slice().sort((a, b) => {
    const cotIdA = parseInt(a.cotId, 10);
    const cotIdB = parseInt(b.cotId, 10);
    return cotIdB - cotIdA;
  });


  const Archive = async (allorder) => {
    // Llamada a la función para obtener el valor de dev3
    await getIdResArchive(allorder);

    if (bud4) {
      // Verifica que dev3 no sea vacío
      const archiInfo = {
        ...bud4
      };

      try {
        //await addDoc(collection(fireDB, "budPendiente"), { ...archiInfo });
        await setDoc(doc(fireDB, "budget", allorder.id), { ...archiInfo });
        toast.success("Restaurado", {
          position: toast.POSITION.TOP_LEFT,
        });

        await deleteBudArchive(allorder);
        await getDeliveryData();

        navigate("../budgets");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("está vacío, no se puede archivar.");
    }
  };
  return (
    <div className="relative overflow-x-auto max-w-screen-xl mx-auto my-5">
        <div>
        <h1 className=" text-center text-2xl">Archivo de Cotizaciones</h1>
      </div>
      <div className="flex mb-2">
        <div className="flex px-6 justify-start mx-0.5 border border-gray-400 py-2 rounded">
          <SearchBar6 />
        </div>
        <div className="flex-grow"></div>


        <Link to={"../budgetbook"}>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            Cotizar
          </button>
        </Link>
        <Link
          to={"../budget"}
          className="bg-clgris hover-bg-clgris text-white py-2.5 px-6 rounded mx-0.5"
        >
          Validar
          <span className="ml-2 text-sm font-medium text-white group-">
            {budgetItems.length}
          </span>
        </Link>
        <div>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            <ModalDelete />
          </button>
        </div>

        <Link to={"../budgets"}>
          <button className="bg-clgris hover:bg-clgris text-white py-2.5 px-6 rounded mx-0.5">
            Regresar
          </button>
        </Link>
      </div>

      

      {filteredData6.length === 0 ? (
        <div className="realtive overflow-x-auto shadow-md">
          <table className="w-full text-sm text-center mb-8">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No. de cotizacion
                </th>
                <th scope="col" className="px-6 py-3">
                  Fecha
                </th>
                <th scope="col" className="px-6 py-3">
                  No. de Tel.
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3">
                  Precio Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Acciones
                </th>
              </tr>
            </thead>

            {sortedOrders.map((allorder) => (
              <tbody key={allorder.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {allorder.id}-{allorder.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {allorder.date}
                  </td>
                  <td className="px-2 py-3 text-black" style={{ width: "10%" }}>{allorder.phoneNumber}</td>
                  <td className="px-6 py-3 text-black">{allorder.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {allorder.grandTotal}
                  </td>
                  <td className="px-6 py-3">
                    <div className=" inline-flex gap-2 cursor-pointer text-black justify-center ">
                      <div>
                        <FaTrash
                          size={20}
                          title="Eliminar"
                          onClick={() => handleDeleteConfirmation(allorder)}
                        />
                      </div>
    
                      <div>
                        <Link to={`../printbudarchive/${allorder.id}`}>
                          <FaPrint size={20} title="Imprimir" />
                        </Link>
                      </div>
                      

                      <div>
                            <ModalRestArch Archive={Archive} dev={allorder} />
                          </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <div className="realtive overflow-x-auto shadow-md">
          <table className="w-full text-sm text-center mb-8">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No. de cotizacion
                </th>
                <th scope="col" className="px-6 py-3">
                  Fecha
                </th>
                <th scope="col" className="px-6 py-3">
                No. de Tel.
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3">
                  Precio Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Acciones
                </th>
              </tr>
            </thead>

            {filteredData6.map((allorder) => (
              <tbody key={allorder.id}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">
                    {allorder.id}-{allorder.iniciales}
                  </td>
                  <td className="px-6 py-3 text-black">
                    {allorder.date}
                  </td>
                  <td className="px-6 py-3 text-black" style={{ width: "10%" }}>{allorder.phoneNumber}</td>
                  <td className="px-6 py-3 text-black">{allorder.name}</td>
                  <td className="px-6 py-3 text-black">
                    Q. {allorder.grandTotal}
                  </td>
                  <td className="px-6 py-3">
                    <div className=" inline-flex gap-2 cursor-pointer text-black justify-center ">
                      <div>
                        <FaTrash
                          size={20}
                          title="Eliminar"
                          onClick={() => handleDeleteConfirmation(allorder)}
                        />
                      </div>
                      
                      <div>
                        <Link to={`../printbudarchive/${allorder.id}`}>
                          <FaPrint size={20} title="Imprimir" />
                        </Link>
                      </div>

                      <div>
                            <ModalRestArch Archive={Archive} dev={allorder} />
                          </div>
                      
                      
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
    </div>
  )
}

export default BudArchive