import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Create = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [rol, setRol] = useState('usuario'); // Valor predeterminado
    const [displayName, setDisplayName] = useState('');
    const navigate = useNavigate()

    const saveuser = async (e) => {
        e.preventDefault()

        if (username.includes(' ')) {
            setError('El username no debe contener espacios en blanco.');
            return;
        }

        const email = `${username}@page.com`

        try {
            // Realiza una solicitud POST al backend para registrar al usuario
            await axios.post('https://us-central1-codileyes.cloudfunctions.net/CreateUser', {
                email,
                password,
                displayName,
                rol,
            });


        } catch (error) {
            // Maneja los errores (puede mostrar un mensaje de error al usuario)
            console.error('Error al registrar usuario:', error);
        }

        navigate('/dashboard/manageUsers')
        //console.log(e.target[0].value)
    }
    return (
        <div className='p-0 sm:p-6'>
            <div className='mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg'>
                <h1 className='text-2xl mb-5 text-center' >Crear Nuevo Usuario</h1>
                <form onSubmit={saveuser}>
                    <div className='flex flex-col'>
                        <label className='mx-5 leading-loose'>Nombre y Apellido</label>
                        <input
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            type="text"
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div className='flex flex-col mb-3'>
                        <label className='mx-5 leading-loose'>Usuario</label>
                        {error && <p className="text-red-500">{error}</p>}
                        <input
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div className='flex flex-col mb-3'>
                        <label className='mx-5 leading-loose'>Contraseña</label>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div className='flex flex-col mb-3'>
                        <label className='mx-5 leading-loose'>Rol</label>
                        <select className='mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600' type="text" value={rol} onChange={(e) => setRol(e.target.value)} style={{ width: '90%' }}>
                            <option value="usuario">Usuario</option>
                            <option value="administrador">Administrador</option>
                        </select>
                    </div>
                    <div className='mt-5 flex justify-center'>
                    <Link to="/dashboard/manageUsers" className='bg-clgris  hover:bg-clgris text-white font-bold py-2.5 px-5  rounded m-2'>Regresar</Link>
                    <button type='submit' className='bg-clgris  hover:bg-clgris text-white font-bold py-2 px-5 rounded m-2'>Guardar Usuario</button>
                    </div>
                </form>
            </div>
        </div>
    )
}



export default Create