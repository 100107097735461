import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../../context/data/myContext";
import { FaPrint, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import SearchBar4 from "../../../components/searchbar/SearchBar4";

function Archive() {
  const context = useContext(MyContext);
  const { arch, deleteArchive, getArchData, filteredData4 } = context;

  useEffect(() => {
    getArchData();
    // eslint-disable-next-line
  }, []);

  const handleDeleteConfirmation = (item) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
      // Aquí puedes realizar la lógica para eliminar el elemento.
      // Puedes agregar tu propia implementación.

      deleteArchive(item);
    } else {
      // El usuario seleccionó 'Cancelar', no hagas nada.
    }
  };

  const [sortedArch, setSortedArch] = useState([]);


 // Ordenar los datos al cargar o cuando arch cambie
 useEffect(() => {
  const sortedData = [...arch].sort((a, b) => {
    // Ordenar por fecha de forma descendente
    return new Date(b.delivery.date) - new Date(a.delivery.date);
  });
  setSortedArch(sortedData);
}, [arch]);

  return (
    <div className="relative overflow-x-auto max-w-screen-xl mx-auto my-4 shadow-md font-rubik ">
      <div className="">
        <div className="text-center py-5">
          <div className="w-full flex justify-end">
            <div className="flex px-6  justify-start mx-0.5 border border-gray-400 py-2 rounded">
              <SearchBar4 />
            </div>
            <div className="flex-grow"></div>
            <Link
              to="../delivery"
              className="bg-clgris hover-bg-clgris text-white py-2.5 px-6 rounded"
            >
              Regresar
            </Link>
          </div>
        </div>

        {filteredData4.length === 0 ? (
          <table className="w-full text-center mb-10">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>

            {sortedArch.map((item, index) => {
              return (
                <tbody>
                  <tr
                    className="bg-gray-50 border-b dark:border-gray-700"
                    key={index}
                  >
                    <td className="px-6 py-3 text-black">
                      
                      {item.devID}
                    </td>
                    <td className="px-6 py-3 text-black">
                      {item.delivery.cot3.name}
                    </td>
                    <td className="px-6 py-3 text-black">
                      Q {item.delivery.cot3.grandTotal}
                    </td>
                    <td className="px-6 py-3 text-black">
                      # {item.delivery.factura}
                    </td>
                    <td className="px-6 py-3 text-black">
                      <div className="inline-flex justify-center gap-2">
                        <div>
                          <FaTrash
                            size={20}
                            key={index}
                            onClick={() => handleDeleteConfirmation(item)}
                          />
                        </div>

                        <div>
                          <Link to={`../printarchive/${item.id}`}>
                            <FaPrint size={20} title="Imprimir" />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          <table className="w-full text-center mb-10">
            <thead className="text-xs border border-clgris text-white uppercase bg-clgris">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>

            {filteredData4.map((item, index) => {
              return (
                <tbody>
                  <tr
                    className="bg-gray-50 border-b dark:border-gray-700"
                    key={index}
                  >
                    <td className="px-6 py-3 text-black">
                      
                      {item.devID}
                    </td>
                    <td className="px-6 py-3 text-black">
                      {item.delivery.cot3.name}
                    </td>
                    <td className="px-6 py-3 text-black">
                      Q {item.delivery.cot3.grandTotal}
                    </td>
                    <td className="px-6 py-3 text-black">
                      # {item.delivery.factura}
                    </td>
                    <td className="px-6 py-3 text-black">
                      <div className="inline-flex justify-center gap-2">
                        <div>
                          <FaTrash
                            size={20}
                            key={index}
                            onClick={() => handleDeleteConfirmation(item)}
                          />
                        </div>

                        <div>
                          <Link to={`../printarchive/${item.id}`}>
                            <FaPrint size={20} title="Imprimir" />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        )}
      </div>
    </div>
  );
}

export default Archive;
