import React, { Fragment, useContext, useState } from 'react'
import MyContext from '../../context/data/myContext';
import { Dialog, Transition } from '@headlessui/react';

function ModalSendArch2() {


    const context = useContext(MyContext);
  const { rangeArch2 } = context;
  const [ startNumber, setStartNumber ] = useState("")
  const [ endNumber, setEndNumber ] = useState("")

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleClick = async (dev) => {
    try {
      openModal();
      
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  
  return (
    <>
      <div className="  text-center text-white">
        <button type="button" onClick={() => handleClick()} >
          Eliminar
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl p-2  text-left align-middle shadow-xl transition-all bg-gray-50">
                  <section className="">
                    <div className="flex flex-col items-center justify-center py-8 mx-auto  lg:py-0">
                      <div className="w-full  rounded-lg md:mt-0 sm:max-w-md xl:p-0 ">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                          <form className="space-y-4 md:space-y-6" action="#">
                            
                            {/* Input for starting number */}
                    <label htmlFor="startNumber" className="block text-sm font-medium text-gray-700">
                      Número de inicio
                    </label>
                    <input
                      type="number"
                      id="startNumber"
                      name="startNumber"
                      value={startNumber}
                      onChange={(e) => setStartNumber(e.target.value)}
                      className="mt-1 p-2 border border-gray-300 rounded-md"
                    />

                    {/* Input for ending number */}
                    <label htmlFor="endNumber" className="block text-sm font-medium text-gray-700">
                      Número final
                    </label>
                    <input
                      type="number"
                      id="endNumber"
                      name="endNumber"
                      value={endNumber}
                      onChange={(e) => setEndNumber(e.target.value)}
                      className="mt-1 p-2 border border-gray-300 rounded-md"
                    />

                          </form>
                          <button
                            type="button"
                            className="focus:outline-none w-full text-white bg-clgris hover:bg-clgris  outline-0 font-medium rounded-lg text-sm px-5 py-2.5 "
                            onClick={() => {
                                rangeArch2(startNumber, endNumber);
                                closeModal();
                              }}
                          >
                            Enviar a Archivo
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ModalSendArch2